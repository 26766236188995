import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bntType: 6,
    tim: null,
    messageShow: false,
    userList: [],
    goupMessage: [],
    messageList:[],
    gameType: false,
    listObj: {},
    secret: sessionStorage.getItem('secret'),
    memberInfo: sessionStorage.getItem('memberInfo'),
    memberId: sessionStorage.getItem('lgUserId'),
    shop: sessionStorage.getItem('shop'),
    shopName: sessionStorage.getItem('shopName')
  },
  mutations: {
    setSecret(state, val) {
      sessionStorage.setItem('secret', val)
      state.secret = val
    },
    setShop(state, shop) {
      state.shop = shop;
      sessionStorage.setItem('shop', JSON.stringify(shop))
    },
    login(state, data) {
      state.memberInfo = data
      state.shopName = data.shopName;
      state.memberId = data.memberId;
      sessionStorage.setItem('shopName', data.shopName)
      sessionStorage.setItem('lgUserId', data.memberId)
      sessionStorage.setItem('memberInfo', JSON.stringify(data))
    }
  },
  actions: {
  },
  modules: {
  }
})
