<template>
  <div class="shopOrder">
    <div class="shop-order-bg" v-show="orderShow"></div>
    <div class="shop-order" v-show="orderShow">
      <div class="tip-close" @click="handlePlayCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div> 
      <div class="order-title">订单列表</div>
      <div class="order-list">
        <ul class="lists-th">
          <li v-for="(item,idx) in thList" :key="idx" >{{item.name}}</li>
        </ul>
        <ul class="lists-td" v-show="listData.length > 0" ref="scrollBox" id="lists-data" @scroll="scrollEvent ">
          <li v-for="(item,idx) in listData" :key="idx">
            <div class="td-top">
              <div class="top-tiem">{{$utils.dateFormat(item.createTime, 'yyyy-MM-dd hh:mm:ss')}}</div>
              <div class="top-number">订单编号：{{item.orderNumber}}</div>
            </div>
            <div class="td-item">
              <div class="item-img">
                <div class="img-border">
                  <img :src="item.coverImg"/>
                </div>
                <div class="img-type">
                  <div class="type-name">
                 {{item.productName}}
                  </div>
                  <div class="type-color" v-if="item.skuValue">
                    分类：{{item.skuString}}
                  </div>
                </div>

              </div>
              <div class="item-num">{{item.buyNum}}</div>
              <div class="item-money">￥{{item.price}}</div>
              <div class="item-status">
              <div class="status-item">{{item.status == 1?'待付款':item.status == 2?'待自提':item.status == 3?'退款':item.status == 4?'完成':'取消'}}</div>
                <div class="status-open" @click="handlePayAdd(item)" v-if="item.status==1">去付款</div>
              </div>
              <div class="item-remark">{{item.pickUpType==1?'门店自提':'--'}}</div>
            </div>
          </li>   
        </ul>

      </div>
    </div>

  </div>
</template>

<script>
import {
  getOrderList,
} from "@/api/balance/shoping"

export default {
  name: "shopOrder",
  data() {
    return {
      orderShow: false,
      thList: [
        {
          name: '商品信息'
        },
        {
          name: '单价及数量'
        },
        {
          name: '实付款'
        },
        {
          name: '订单状态'
        },
        {
          name: '提货方式'
        },
      ],
      listData: [],
      active: 1,
      totalNum: 0,
      loading: false,
      searchData: {
        status: '',
        pageSize: 10,
        lgUserId: ''
      },
    };
  },
  components: {},
  methods: {
    handleListData(){
      this.orderShow = false
      this.active = 1
      this.listData = []

    },
    handlePayAdd(item) {
      this.orderShow = false
      this.active = 1
      this.listData = []
      this.$parent.handlePayOrderData(item)
    },
    // 滚动加载动态
    scrollEvent(e) {
      let outerClientHeight = this.$refs.scrollBox.clientHeight; // 外层盒子可视高度
      let outerScrollTop = this.$refs.scrollBox.scrollTop; // 外层盒子卷去的高度
      let innerScrollHeight = document.querySelector("#lists-data").scrollHeight; // 内层盒子全部的高度
      if (innerScrollHeight - outerScrollTop - outerClientHeight <= 50) {
        if (
          this.listData.length > this.totalNum ||
          this.totalNum == this.listData.length
        ) {
        } else {
          if (this.loading) {
            this.loading = false;
            this.active = this.active + 1;
            this.loadMore();
          }
        }
      }
    },
    loadMore() {
      this.handleOrderList();
    },
    handleOrderList() {
      let params = {
        status: this.searchData.status,
        pageSize: this.searchData.pageSize,
        pageNum: this.active,
        lgUserId: sessionStorage.lgUserId
      }
      getOrderList(params).then(res => {
        if (res.code == 200) {
          this.totalNum = res.total;
          this.listData = this.listData.concat(res.rows);
          this.loading = this.active < res.total;
          for(let i in this.listData) {
            if(this.listData[i].skuValue) {
              this.listData[i].skuStatus = JSON.parse(this.listData[i].skuValue) 
              for(let j in this.listData[i].skuStatus) { 
                if(j == this.listData[i].skuStatus.length-1) {
                  this.listData[i].skuString += this.listData[i].skuStatus[j].value
                }else{
                  this.listData[i].skuString += this.listData[i].skuStatus[j].value + '-'
                }
              }

            }
          }
          if(this.listData.length > 0) {
            this.orderShow = true
          }
        } 
      }).catch(res=>{
      });
    },
    // 关闭弹窗
    handlePlayCloseData() {
      this.orderShow = false
    },
    // 打开弹窗
    handlePlayOpenData() {
    }

  },
  created() {},
  mounted() {
    // this.handleOrderList()
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
@import "./shopOrder.less";

</style>
