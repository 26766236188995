<template>
  <div class="chatRoom">
    <!-- 这个是聊天室 -->
    <div class="cantent">
      <div class="title">{{groupObj.name}}</div>
      <div class="message">
        <ul class="message-list" id="chatRoomText"  ref="scrollBox" >
          <li v-for="(item,idx) in messageList" :key="idx">
            <div class="item-my" v-if="item.myStatus && !item.isDeleted && !item.isRevoked">
              <div class="item-right" >
                <div class="flex-left"> <span>{{item.time}}</span>  {{item.nickName}}</div>
                <div class="flex-right" v-if="!item.isRevoked">
                  <div class="text-left"></div>
                  <div class="text-right"  @contextmenu.prevent="rightShow(item,idx)" v-if="item.text"  v-html="item.text.replace(/(\r\n|\n|\r)/gm,'<br />')"></div>
                  <div class="text-right-img" v-else   @click.stop="handleLookImg(item.img)">
                    <img  @contextmenu.prevent="rightShow(item,idx)" :src="item.img"/>
                  </div>
                  <!-- <div class="flex-tip" :class="{'last-tip':idx == messageList.length-1}" v-if="item.selectd && idx == listIdx">
                    <div  @click.stop="handleRevocation(item,idx)" v-if="nowTime<item.isRevokedTime">撤回</div>
                    <div v-else @click.stop="handleDeleteMessage(item,idx)">删除</div>
                  </div> -->
                </div>
              </div>
              <div class="to-head">
                <img :src="item.headImg"/>
              </div>
            </div>
            <div class="item-to" v-if="!item.myStatus&& !item.isRevoked">
              <div class="to-head">
                <img :src="item.headImg"/>
              </div>
              <div class="item-right">
                <div class="flex-left">{{item.nickName}} <span>{{item.time}}</span></div>
                <div class="flex-right">
                  <div class="text-right" @contextmenu.prevent="rightShow(item,idx)" v-if="item.text"  v-html="item.text.replace(/(\r\n|\n|\r)/gm,'<br />')"></div>
                  <div class="text-right-img" v-else  @click="handleLookImg(item.img)">
                    <img @contextmenu.prevent="rightShow(item,idx)" :src="item.img"/>
                  </div>
                  <div class="text-left"></div>
                </div>
              </div>
            </div>
            <!-- <div class="item-isRevoked" v-if=" item.isRevoked" >
               {{item.myStatus?'你':item.nickName}}撤回了一条信息 <span class="" v-if="nowTime<item.isRevokedTime && item.myStatus && item.text" @click="handleEditMessage(item)"> 重新编辑</span>
            </div> -->
          </li>
        </ul>
        <div class="message-add">
          <div class="foot-input">
            <el-input
              resize="false"
              type="textarea"
              ref="textInput"
              id="testPasteInput"
              @keydown.native="keydown"
              :rows="2"
              placeholder="说点什么..."
              v-model="addForm.content">
            </el-input>
          </div> 
          <div class="foot-img" @click="handleImgAdd">
            <input @change="handleFileData" :type="inputType" accept="image/*"
            name="uploader-input" 
            ref="uploaderGroup"
            class="uploader-file"
            id="imagePicker" />
            <i class="el-icon-picture"></i>
          </div>
          <div class="foot-add" @click="handleTomessage">
            <img src="~@/assets/images/balance/first/add.png"/>
            
          </div>

        </div>
      </div>
    </div>
    <div class="right">
      <div class="right-notice">
        <div class="notice-title">公告</div>
        <div class="notice-content">
          {{groupObj.notification}}  
        </div>
      </div>
      <div class="right-list">
        <div class="list-title">在线人数（{{groupObj.memberCount}}）</div>
        <ul class="list-users" id="usersText" ref="userScrollBox" @scroll="scrollEventUsers ">
          <li v-for="(item,idx) in userList" :key="idx">
            <img class="user-img" :src="item.avatar"/>
            <div class="user-name">{{item.nick}}</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="look-tip-bg" v-show="lookShow"></div>
    <div class="look-tip" v-show="lookShow">
      <img  class="look-img" :src="lookUrl"/>
      <div class="tip-close" @click="handleLookClose"  style="-webkit-app-region: no-drag;">
        <img  src="~@/assets/images/balance/user/close.png"/>
      </div>
    </div>
  </div>
</template>

<script>
import TIM from 'tim-js-sdk'
import TIMUploadPlugin from 'tim-upload-plugin';
import {
  getAppData,addGroupMember
} from "@/api/balance/message";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC,getAstro }  from '@/common/utils/des'
export default {
  name: "chatRoom",
  data() {
    return {
      groupObj: {
        name: '',
        notification: '',
        introduction: '',
        memberCount: 0
      },
      userList:[],
      messageList: [],
      appSDKApp: {},
      addForm: {// 保存发送消息内容
        content: '',
        avatar: '',
        nick: '',
      }, 
      inputType: 'file',
      addMessage:null,
      // 查看大图
      lookShow: false,
      lookUrl: '',
      listIdx: '',
      lgUserId: '',
      lastHeight: 0,

      loading: false,
      userLoading: false,
      nowTime: null,
      handleTestPasteInput: null
    };
  },
  components: {  },
  computed: {
  },
  methods: {
    handleGroupProfile(type) {
      let _this = this
      _this.userList = _this.$store.state.userList
      let promise = this.tim.getGroupProfile({ groupID: _this.appSDKApp.groupId, groupCustomFieldFilter: ['key1','key2'] });
      promise.then(function(imResponse) {
        _this.groupObj =  imResponse.data.group
        if(type) {
          _this.groupObj.memberCount = imResponse.data.group.memberCount
        }
      }).catch(function(imError) {
        console.warn('getGroupProfile error:', imError); // 获取群详细资料失败的相关信息
      });
    },
    /**
     * 重新编辑
     */
    handleEditMessage(item,idx) {
      this.addForm.content = item.text
    },
    /**
     * 删除消息
     */
    handleDeleteMessage(item,idx) {
      let _this = this
      let promise = _this.tim.deleteMessage([item.itemObj]);
      promise.then(function(imResponse) {
        // 删除消息成功
        _this.messageList[idx].selectd = false
        _this.messageList[idx].isDeleted  = true
        _this.$forceUpdate()
      }).catch(function(imError) {
        _this.messageList[idx].selectd = false
        // 删除消息失败
        console.warn('deleteMessage error:', imError);
      });
    },
    /**
     * 撤回
     */
    handleRevocation(item,idx,message) {
      let _this = this
      let promise = _this.tim.revokeMessage(item.itemObj);
      promise.then(function(imResponse) {
        _this.messageList[idx].selectd = false
        _this.messageList[idx].isRevoked = true
        _this.$forceUpdate()
        // 消息撤回成功
      }).catch(function(imError) {
        _this.messageList[idx].selectd = false

        // 消息撤回失败
        console.warn('revokeMessage error:', imError);
      });

    },
    /**
     * 右键点击事件
     */
    rightShow(item,idx) {
      this.nowTime = new Date().getTime()
      this.listIdx =  idx
      for(let i in this.messageList) {
        if(idx == i ) {
          this.messageList[idx].selectd = true
        }else{
          this.messageList[i].selectd = false
        }
      }
      this.$forceUpdate();
    },
    // 关闭查看大图
    handleLookClose() {
      this.lookShow = false
    },
    // 查看大图
    handleLookImg(url){
      this.lookShow = true
      this.lookUrl = url

    },
    /**
     * 加载更多群成员
     */
    scrollEventUsers() {
      let outerClientHeight = this.$refs.userScrollBox.clientHeight; // 外层盒子可视高度
      let outerScrollTop = this.$refs.userScrollBox.scrollTop; // 外层盒子卷去的高度
      let innerScrollHeight = document.querySelector("#usersText").scrollHeight; // 内层盒子全部的高度
      if (innerScrollHeight - outerScrollTop - outerClientHeight <= 10) {
        if (!this.userLoading) {
          this.userLoading = true;
          this.hanleGroupMemberList(true)
        }
      }

    },
    isImage(fileName) {
      if (typeof fileName !== "string") return;
        let name = fileName.toLowerCase();
      return (
        name.endsWith(".png") ||
        name.endsWith(".jpeg") ||
        name.endsWith(".jpg") ||
        name.endsWith(".png") ||
        name.endsWith(".bmp") ||
        name.endsWith(".gif")
      );
    }, 
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        //开始转
        reader.onload = function() {
          fileResult = reader.result;
        };
        //转 失败
        reader.onerror = function(error) {
          reject(error);
        };
        //转 结束  咱就 resolve 出去
        reader.onloadend = function() {
          resolve(fileResult);
        };
      });
    },
    handleFileData(e)  {
      var file = e.target.files[0]; // js获取文件对
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
        return
      }
      let type = this.isImage(file.name);
      if (!type) {
        this.$message.error("只允许上传图片!");
        return;
      }
      this.inputType = 'text'
      this.handleImgMessageShow(file)
    },
    // 发送消息
    handleTomessage() {
      if(!this.addForm.content || !this.addForm.content.replace(/(\r\n|\n|\r)/gm,'')) {
        this.$message.error('不能发空白信息!');
        return
      }
      this.addForm.avatar = sessionStorage.headImg
      this.addForm.nick = sessionStorage.nickName
      this.addForm.item = new Date().getTime()
      this.addForm.myStatus = true
      this.handleAddMessage()
    },
    handleImgMessageShow(file) {
      let _this = this
      _this.addMessage = _this.tim.createImageMessage({
        to:  _this.appSDKApp.groupId,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          file:file,
        },
        // 消息自定义数据（云端保存，会发送到对端，程序卸载重装后还能拉取到，v2.10.2起支持）
        // cloudCustomData: 'your cloud custom data'
        onProgress: function(event) { console.log('file uploading:', event) }

        })
        _this.getBase64(file).then(res=>{
          _this.inputType = 'file'
          _this.addForm.img = res;
          _this.$nextTick(()=>{
            _this.handleMessageAdd(true)

          })
        })
    },
    /**
     * 图片消息 
     */
    handleImgMessage(e) {
      let _this = this
      let clipboardData = e.clipboardData;
      let fileCopy;
      let file;
      if (clipboardData && clipboardData.files && clipboardData.files.length > 0) {
        file = clipboardData.files[0];
        // 图片消息发送成功后，file 指向的内容可能被浏览器清空，如果接入侧有额外的渲染需求，可以提前复制一份数据
        fileCopy = file.slice();
      }
      if (typeof file === 'undefined') {
        return;
      }
      _this.addMessage = _this.tim.createImageMessage({
        to:  _this.appSDKApp.groupId,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
          file:file,
        },
        onProgress: function(event) { console.log('file uploading:', event) }

        })
        _this.getBase64(file).then(res=>{
          _this.inputType = 'file'
          _this.addForm.img = res;
          _this.$nextTick(()=>{
            _this.handleMessageAdd(true)

          })
        })
    },
    /**
     * 文本消息
     */
    handleAddMessage() {
      let _this = this
      _this.addMessage = _this.tim.createTextMessage({
        to:  _this.appSDKApp.groupId,
        conversationType: TIM.TYPES.CONV_GROUP,
        payload: {
         text:_this.addForm.content
        },
      });
      _this.handleMessageAdd()
    },
    // 展示消息
    handleMessageAdd(type) {
      let _this = this
      let obj = {}
      obj.itemObj = _this.addMessage
      // 2. 发送消息
      let promise = _this.tim.sendMessage(_this.addMessage);
      promise.then(function(imResponse) {
        // 发送成功
        obj.headImg = sessionStorage.headImg
        obj.nickName = sessionStorage.nickName
        obj.time = _this.$utils.dateFormat(new Date(), 'hh:mm')
        obj.myStatus = true
        obj.isRevoked = false
        obj.isRevokedTime = new Date().getTime() + (60*1000 *2)
        obj.isDeleted = false
        if(_this.addForm.content) {
          obj.text = _this.addForm.content
          _this.messageList.push(obj)
        }else if(_this.addForm.img){
          obj.img = _this.addForm.img
          _this.messageList.push(obj)
        }
        _this.addForm.content = ''
        _this.addForm.img = '';
        _this.handleMessageScrollTop()
        _this.$store.state.goupMessage = _this.messageList
        // _this.addMessage=null
      }).catch(function(imError) {
        _this.addMessage=null
        _this.addForm.content = ''
        _this.addForm.img = '';
        _this.$message.error(imError.message);
        // 发送失败
        console.warn('sendMessage error:', imError);
      });
    },
    /**
     * 或者打开页面聊天时候自动滚动到最下面一条
     */
    handleMessageScrollTop() {
      this.$nextTick(() => {
        var msgbox = document.querySelector("#chatRoomText");
        msgbox.scrollTop = msgbox.scrollHeight;
      });
    },
    /**
     * 消息滚动到最顶层
     */
    handleMessageTop() {
      this.$nextTick(() => {
        var msgbox = document.querySelector("#chatRoomText");
        msgbox.scrollTop = document.querySelector("#chatRoomText").scrollHeight-this.lastHeight- 200;
      });

    },
    // 发送图片
    handleImgAdd() {
      this.$refs.uploaderGroup.dispatchEvent(new MouseEvent('click')) 
    },
    keydown(e) {
      if(!e.shiftKey  && e.keyCode == 13 ) {
        e.cancelBubble = true // IE阻止冒泡
        e.stopPropagation(); // firefox阻止冒泡
        e.preventDefault(); // 取消事件默认动作
        this.handleTomessage() 
        if(this.addForm.text) {
          this.addForm.img = ''
        }else{
          // this.handleImgMessageShow()
        }
      }
    },
    /**
     * 获取签名
     */
    handleAppData() {
      let _this = this
      _this.tim =  _this.$store.state.tim 
      getAppData({lgUserId: sessionStorage.lgUserId}).then(res=>{
        if(res.code == 200) {
          _this.appSDKApp = JSON.parse(decryptByDESModeCBC(res.data.data));
          if(sessionStorage.IMShow) {
            _this.handleAddGroupMember()
            _this.hanleGroupMemberList()
            _this.handleGroupProfile()
            _this.messageList = _this.$store.state.goupMessage
            _this.$nextTick(()=>{
              _this.handleMessageScrollTop()
            })
            // this.handleMessageData()
            // _this.login()
          }
      
        }
      })
    },
    /**
     * 创建IM
     */
    createMessage() {
      var options = {
        SDKAppID: Number(this.appSDKApp.appId)  // 接入时需要将 0 替换为您的云通信应用的 SDKAppID
      };
      this.tim = TIM.create(options);
      this.$store.state.tim = this.tim
      // this.tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
      this.tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用
      // 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
      this.tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});
      // this.roomInitListener()
      if(!sessionStorage.IMShow) {
      }else{
        this.hanleGroupMemberList(false)
        this.handleGroupProfile()
        // this.dialogueList()
      }
    },
     /**
      * IM 登录 
      */
    login(item) {
      let lgUserId = sessionStorage.lgUserId
      let _this = this
      _this.appSDKApp = item
      _this.tim =  _this.$store.state.tim 
      let userSig = item.userSig
      let promise = _this.tim.login({userID: lgUserId, userSig: userSig});
        promise.then(function(imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
            // _this.handleUserList()
          }else{
            sessionStorage.IMShow = true
            // _this.handleAddGroupMember(item)
            // _this.hanleGroupMemberList(false)
            // _this.handleGroupProfile()
          
          }
        }).catch(function(imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
    },

    hanleGroupMemberListUpdate(){
      this.userList = []
      this.hanleGroupMemberList(false)
    },
    /**
     * 获取群成员列表
     */
    hanleGroupMemberList(type) {
      let _this = this
      let arr = []
      let promise = _this.tim.getGroupMemberList({ groupID: _this.appSDKApp.groupId, count: 30, offset: _this.userList.length }); // 从0开始拉取30个群成员
      promise.then(function(imResponse) {
        if(imResponse.data.memberList.length > 0) {
          _this.userLoading = false;
        }else{
          _this.userLoading = true
        }
          arr = imResponse.data.memberList
        if(!type) {
          _this.userList = arr
          let listObj = {};
          let peon = _this.userList.reduce((cur,next) => {
          listObj[next.userID] ? "" : listObj[next.userID] = true && cur.push(next);
          return cur;
          },[])
          _this.userList  = peon
        }else{
          _this.userList =_this.userList.concat(arr)
        }
        _this.$store.state.userList = _this.userList
      }).catch(function(imError) {
        console.warn('getGroupMemberList error:', imError);
      });
    },
   
    handleMessageData() {
      let _this = this
      _this.messageList = _this.$store.state.goupMessage
      if(_this.messageList.length > 300 ) {
        _this.messageList.shift() 
      }
      _this.$store.state.goupMessage =  _this.messageList 
      _this.$forceUpdate()
      _this.$nextTick(()=>{
        _this.handleMessageScrollTop()

      })
    
    },
  
    /**
     * 全局鼠标事件
     */
    clickOther() {
      this.nowTime = new Date().getTime()
      if(this.messageList.length>0) {
        if(this.listIdx) {
          this.messageList[this.listIdx].selectd = false
          this.listIdx  = ''
        }
      }
    },
    handleAddGroupMember() {
      // let _this = this
      let promise = this.tim.joinGroup({ groupID: this.appSDKApp.groupId, type: TIM.TYPES.GRP_AVCHATROOM });
      promise.then(function(imResponse) {
        switch (imResponse.data.status) {
          case TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL: // 等待管理员同意
            break;
          case TIM.TYPES.JOIN_STATUS_SUCCESS: // 加群成功
            break;
          case TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP: // 已经在群中
            break;
          default:
            break;
        }
      }).catch(function(imError){
        console.warn('joinGroup error:', imError); // 申请加群失败的相关信息
      });
    },
    destroyIm() {
      // this.tim.off(TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
    },
  },
  created() {
    this.lgUserId  = sessionStorage.lgUserId
  },
  mounted() {
    /**
     * 全局监听鼠标点击事件
     */ 
    if(sessionStorage.IMShow) {
      this.handleAppData()

    }
    document.getElementById('testPasteInput').addEventListener('paste', this.handleImgMessage)
    window.addEventListener("click", this.clickOther);
    this.$nextTick(()=>{
    });
  },
  beforeDestroy() {  // 实例销毁之前对点击事件进行解绑
    window.removeEventListener('click', this.clickOther);
    document.removeEventListener('paste', this.handleImgMessage)
    
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
@import "./index.less";

</style>
