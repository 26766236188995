<template>
  <div class="guessing">
    <div class="guessing-left">
      <div class="left-tab">
        <div class="tab0" @click="handleTab(0)">
          <div class="tab-item tab-bg0" :class="tebIdex==0?'tab-bg-show0':''">
            <img src="~@/assets/images/guessing/tab3.png" />
          </div>
          <div class="tab-text">记录</div>
        </div>
        <div class="tab1" @click="handleTab(99)">
          <div class="tab-item tab-bg99" :class="tebIdex==99?'tab-bg-show0':''">
            <img src="~@/assets/images/guessing/all.png" />

          </div>
          <div class="tab-text">全部</div>
        </div >
        <div class="tab1" @click="handleTab(1)">
          <div class="tab-item tab-bg1" :class="tebIdex==1?'tab-bg-show0':''">
            <img src="~@/assets/images/guessing/tab2.png" />

          </div>
          <div class="tab-text">LOL</div>
        </div >
        <div class="tab2" @click="handleTab(2)">
          <div class=" tab-item tab-bg2" :class="tebIdex==2?'tab-bg-show0':''">
            <img src="~@/assets/images/guessing/tab1.png" />
          </div>
          <div class="tab-text">绝地求生</div>
        </div>
        <div class="tab2" @click="handleTab(3)">
          <div class=" tab-item tab-bg3" :class="tebIdex==3?'tab-bg-show0':''">
            <img src="~@/assets/images/guessing/tab4.png" />
          </div>
          <div class="tab-text">和平精英</div>
        </div>
        <div class="tab2" @click="handleTab(4)">
          <div class=" tab-item tab-bg4" :class="tebIdex==4?'tab-bg-show0':''">
            <img src="~@/assets/images/guessing/tab5.png" />
          </div>
          <div class="tab-text">守望先锋</div>
        </div>
        <div class="tab2" @click="handleTab(5)">
          <div class=" tab-item tab-bg5" :class="tebIdex==5?'tab-bg-show0':''">
            <img src="~@/assets/images/guessing/tab6.png" />
          </div>
          <div class="tab-text">LoL手游</div>
        </div>
      </div>
    </div>
    <div class="guessing-right">
      <!-- 记录 -->
      <div class="right-list" v-if="tebIdex==0">
        <div class="lists-title">
          <div class="lists-left">
            竞猜记录
          </div>
          <div class="lists-right">
            <div class="block"> 
              <el-date-picker
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                value-format="yyyy-MM-dd"
                end-placeholder="结束日期">
              </el-date-picker>
            </div>
            <div class="search" @click="handleSearch">查询</div>
          </div>
        </div>
        <div class="lists-table">
          <el-table
            v-loading="loading"
            :data="tableData"
            border
            size="mini" 
            height="505px"
            :header-cell-style="{'background': '#EBF1FE','color':'#000'}"
            style="width: 100%">
            <!-- <el-table-column prop="openingNumber" label="期数" width="85px"> 
            </el-table-column> -->
            <el-table-column prop="activityType" :show-overflow-tooltip="true" label="活动项目" width="100px"> 
              <template slot-scope="scope" class="row" >
                <div class="li-name">{{scope.row.activityType==1?'绝地求生':scope.row.activityType==2?'和平精英':scope.row.activityType==3?'英雄联盟':scope.row.activityType==4?'守望先锋':'英雄联盟手游'}}</div>

              </template>
            </el-table-column>
            <el-table-column prop="gameName" label="赛事名称" :show-overflow-tooltip="true" width="90px"> </el-table-column>
            <el-table-column prop="activityName" label="活动名称" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="participation" :show-overflow-tooltip="true" label="参与项" width="110px"> 
              
              <template slot-scope="scope" class="row">
                <span v-for="(res,idx) in scope.row.oddsShow" :key="idx">
                  <span v-if="res.id == scope.row.oddsId">{{res.name}} {{res.odds}}</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="bettingMoney" label="参与网费" width="75px"> </el-table-column>
            <el-table-column prop="status" label="开奖结果" width="75px"> 

              <template slot-scope="scope" class="row">
                <span :class="scope.row.status==2?'statusRed':''">
                  {{scope.row.status==1?'未开奖':scope.row.status==2?'中奖':'未中奖'}}

                </span>
              </template>
            </el-table-column>
            <el-table-column prop="winningMoney" label="网费" width="60px"> 
              <template slot-scope="scope" class="row">
                <span :class="scope.row.status==2?'statusRed':''">
                  {{scope.row.winningMoney}}

                </span>
              </template>

            </el-table-column>
            <el-table-column prop="createTime" label="时间" width="155px"> </el-table-column>
          </el-table>
        </div>
        <div class="lists-page">
          <pagination
            v-show="search.total > 0"
            :total="search.total"
            :page.sync="search.params.pageNum"
            :limit.sync="search.params.pageSize"
            @pagination="firstData"
          />
        </div>
      </div>

      <!-- lol -->
      <div class="right-list" v-else>
        <div v-if="lists.length">
          <div class="list-li" v-for="(item,idx) in lists " :key="idx">
            <div class="li-all">
              <div class="li-name">{{item.activityType==1?'绝地求生':item.activityType==2?'和平精英':item.activityType==3?'英雄联盟':item.activityType==4?'守望先锋':'英雄联盟手游'}}</div>
              <div class="li-status">
                <div class="status-text">{{item.gameName}}</div>
                <div class="status-show" v-if="item.gameName.length>10">
                  {{item.gameName}}
                </div>
                </div>
              <div class="li-teams">
                <div class="teams-text" >{{item.activityName}}</div>
                <div class="teams-show" v-if="item.activityName.length>20">
                  {{item.activityName}}
                </div>
              </div>
              <div class="li-ratio-list">
                <div  class="li-ratio" v-for="(res,index) in item.oddsShow" :key="index">
                  <div class="ratio-item" @click="handleBetData(item,res)">
                    <div>{{res.name}}</div>
                    <div> {{res.odds}}</div>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="no-list" v-else>暂无投注,请耐心等待哦~</div>
      </div>
    </div>
    <!-- 投注弹窗 -->
    <!-- <el-dialog
      :title="bet.title"
      :visible.sync="bet.is"
      top="30vh"
      width="500px"
      class="bet-modal"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="bet-list">
        <div class="ratio-item" >
          <div>{{bet.res.name}}</div>
          <div> {{bet.res.odds}}</div>
        </div>
        <div class="list-flex list-flex-top"> 
          <div class="flex-left">我的网费：{{info.balance}}</div>
        </div>
        <div class="list-flex"> 
          <div class="flex-left">倍数：{{bet.res.odds}}</div>
          <div class="flex-right">竞猜成功获得：<span class="right-money">{{moneyShow}}</span><span class="right-tip">（含参与网费）</span></div>
        </div>
        <div class="list-input">
          <el-input maxlength="4" @input="handleBetMoney" v-model="betMoney" placeholder="请输入参与的数量"></el-input>
          <div class="tipText">{{tipText}}</div>
        </div>
      </div>
      <div class="row list-btn">
        <div class="btn-add" v-loading="loading" @click="handleBtnAdd">
          确定
        </div>
        
      </div>
    </el-dialog> -->
    <div class="guessing-show-bg" v-show="bet.is"></div>
    <div class="guessing-show-item" v-show="bet.is">
      <div class="tip-close" style="-webkit-app-region: no-drag;" @click="handleGuessingClose">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="bet-title">{{bet.item.activityName}}</div>
      <div class="bet-list">
        <div class="ratio-item" >
          <div>{{bet.res.name}}</div>
          <div> {{bet.res.odds}}</div>
        </div>
        <div class="list-flex list-flex-top"> 
          <div class="flex-left">我的网费：{{info.presentedMoney}}</div>
        </div>
        <div class="list-flex"> 
          <div class="flex-left">倍数：{{bet.res.odds}}</div>
          <div class="flex-right">竞猜成功获得：<span class="right-money">{{moneyShow}}</span><span class="right-tip">（含参与网费）</span></div>
        </div>
        <div class="list-input">
          <el-input maxlength="4" @input="handleBetMoney" v-model="betMoney" placeholder="请输入参与的数量"></el-input>
          <div class="tipText">{{tipText}}</div>
        </div>
      </div>
      <div class="row list-btn">
        <div class="btn-add" v-loading="loading" @click="handleBtnAdd">
          确认
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>

import {
  guessList,
  betList,
  userinfo,
  guessBet
} from "@/api/wallet";
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
export default {
  name: 'guessing',
  data() {
    return {
      search: {
        is: false,
        val: "",
        params: {
          page: 1,
          pageSize: 13,
          isAsc: 1,
          name: ""
        },
        total: 0,
        list: []
      },
      lists:[],
      tebIdex: 0,
      // 表格
      loading: false,
      value1: '',
      tableData: [],
      secret:{},
      activityType: 1,
      // 投注弹窗
      moneyShow: 0,
      tipText: '',
      loading: false,
      info: {},
      betMoney: null,
      bet: {
        title: 'TT vs JDG  BODG  BO33 谁会取得胜利',
        is: false,
        item:{},
        res: {}
      },
    }
  },
  methods: {
    handleGuessingClose() {
      this.bet.is = false
    },
    //获取个人信息
    handleUserinfo() {
      userinfo().then(res => {
        if (res.code == 200) {
          this.info = JSON.parse(decryptByDESModeCBC(res.msg));
          return false;
        }
      }).catch(res=>{
      });

    },
    // 算出投注后所得费用
    handleBetMoney(data) {
      var reg = /^[0-9]+.?[0-9]*$/;
      if(reg.test(data)) {
        this.tipText = ""
        if(this.betMoney>this.info.balance) {
          this.betMoney = this.info.balance
          this.tipText = "金额不能大于余额哦~"
          this.moneyShow = (this.betMoney*this.bet.res.odds).toFixed(2)
        } else{
          this.moneyShow = (this.betMoney*this.bet.res.odds).toFixed(2)
        }
        return
      }
      else if(!data){
        this.tipText = ""
        this.moneyShow = 0
      } else {
        this.moneyShow = 0
        this.tipText = "格式不正确,请输入数字~"
      }
    },
    // 打开投注弹窗
    handleBetData(item,res) {
      this.tipText = ""
      this.bet.title = item.statusName
      this.bet.is =true
      this.bet.res =res
      this.bet.item =item
      this.moneyShow = 0
      this.betMoney = null
    },
    // 获取投注列表
    haneleGuessList() {
      guessList({activityType: this.activityType}).then(res => {
        if (res.code == 200) {
          let arr = []
          this.lists = res.data
          for(let i in this.lists) {
            this.lists[i].oddsShow =  JSON.parse(this.lists[i].oddsJson) 
            this.lists[i].teamsShow = false
          }
        }
      }).catch(res=>{
      });
    },
    // 搜索
    handleSearch() {
      this.search.params.pageNum = 1
      this.firstData()
    },
    // 切换左边菜单栏
    handleTab(type) {
      let info = JSON.parse(decryptByDESModeCBC(sessionStorage.secret));
      this.lists = []
      this.tebIdex = type
      this.activityType = ''
      this.search.params.startTime = '';
      this.search.params.endTime = '';
      if(this.tebIdex == 0) {
        this.firstData()

      } else if(this.tebIdex == 1) {
        this.activityType = 3
        this.haneleGuessList()
      } else if(this.tebIdex == 2) {
        this.activityType = 1
        this.haneleGuessList()
        
      } else if(this.tebIdex == 3) {
        this.activityType = 2
        this.haneleGuessList()

        
      } else if(this.tebIdex == 4) {
        this.activityType = 4
        this.haneleGuessList()
      }else if(this.tebIdex == 99) {
        this.activityType = ''
        this.haneleGuessList()
        
      }else{
        
        this.activityType = 5
        this.haneleGuessList()
      }
      this.handleUserinfo()
    },
    // 获取第一个 进入数据
    firstData() {
      var params = {};
      params.pageNum = this.search.params.pageNum;
      params.pageSize = this.search.params.pageSize;
      params.isAsc = this.search.params.isAsc;
      if(this.value1 && this.value1.length>0) {
        this.search.params.startTime = this.value1[0] + ' 00:00:00';
        this.search.params.endTime = this.value1[1]+ ' 23:59:59';

      }else{
        this.search.params.startTime = ''
        this.search.params.endTime = ''
      }
      params.startTime = this.search.params.startTime
      params.endTime = this.search.params.endTime
      betList(params).then(res => {
        if (res.code == 200) {
          this.tableData = res.rows
          this.search.total = res.total
          for(let i in this.tableData) {
            this.tableData[i].oddsShow =  JSON.parse(this.tableData[i].oddsJson) 
            if (this.tableData[i].oddsId) {
              
            }
          }
        }
      }).catch(res=>{
      });
    },
    // 提交
    handleBtnAdd() {
      
      var reg = /^[0-9]+.?[0-9]*$/;
      if(!this.betMoney || !reg.test(this.betMoney)) {
        this.tipText = "格式不正确,请输入数字~"
        return
      }
      if(this.betMoney < 1) {
        this.tipText = "金额不能小于1哦~"
        return
      }
      if(this.betMoney>this.info.balance) {
        this.tipText = "金额不能大于余额哦~"
        return
      }
      let params = encryptByDESModeCBCUtf8to64(JSON.stringify({
        money: this.betMoney,
        id:this.bet.item.id,
        oddsId: this.bet.res.id,
      }))
      this.loading = true
      guessBet({data: params}).then(res => {
        if (res.code == 200) {
          this.$message({
              message: '投注成功~',
              type: 'success',
          });
          this.loading = false 
          this.bet.is = false
        }else{
          this.loading = false 
          this.$message.error(res.msg);

        }
      }).catch(res=>{
        this.loading = false 
      });

    }
  },
  created(){

  },
  mounted() {
    this.firstData()
  },
  destroyed(){
  }
}
</script>
<style lang="less">
.guessing{
  color: #333;
  background: #fff;
  display: flex;
  // border-radius: 38px 38px 0 0;
  .guessing-left{
    width: 90px;
    height: calc(100vh - 90px);
    margin-top: 10px;
    padding-bottom: 30px;
    overflow-x: scroll;
    box-shadow: 0px 11px 21px 0px rgba(195, 211, 222, 0.4);
    .left-tab{
      text-align: center;
      .tab-text{
        margin-top: 4px;
        font-size: 14px;
        color: #666666;
      }
      >div{
        margin-top: 30px;
        &:first-child{
          margin-top: 0;
        }
      }
      .tab-item{
        width: 45px;
        height: 46px;
        margin: auto;
        border-radius: 50%;
        cursor: pointer;
        background: #EFFAFD;
      }
      .tab-bg-show0{
        // border: 6px solid #F7FDFE;
        box-shadow: 0px 13px 20px 0px rgba(195, 211, 222, 0.6);
      }
      .tab-bg0{
        >img{
          width: 23px;
          height: 26px;
          margin-top: 10px;
        }
      }
      .tab-bg1{
        >img{
          width: 24px;
          height: 27px;
          margin-top: 10px;
        }
      }
      .tab-bg2{
        >img{
          width: 26px;
          height: 27px;
          margin-top: 10px;
        }
      }
      .tab-bg3{
        >img{
          width: 27px;
          height: 27px;
          margin-top: 10px;
        }
      }
      .tab-bg4{
        >img{
          width: 25px;
          height: 24px;
          margin-top: 10px;
        }
      }
      .tab-bg5{
        >img{
          width: 26px;
          height: 14px;
          margin-top: 35%;
        }
      }
    }
  }
  .guessing-right{
    width: 100%;
    margin-left: 7px;
    height: 100%;
    // overflow-y: scroll;
    .no-list{
      width: 200px;
      margin: 30% auto;
      text-align: center;
      color: #999;
    }
    .right-list{
      // margin-top: 20px;
      .list-li{
        padding:0 40px;
        font-size: 11px;
        &:hover{
          color: #255135;
          background: #F4FCFE;
          .li-ratio{
            color: #666666;
          }
        }
        &:last-child{
          border-bottom: none
        }
        .li-all{
          display: flex;
          align-items: center;
          padding: 22px 0;
          border-bottom: 1px solid #E7E9EC;
          >div{
            padding:0 15px ;
          }
        }
        .li-name{
          flex: 1;
          max-width: 80px;
          padding-left: 0;
        }
        .li-status{
          position: relative;
          flex: 2;
          max-width: 160px;
          &:hover{
            .status-show{
              display: block;
            }
          }
          .status-text{
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
          .status-show{
            display: none;
            position: absolute;
            background: rgba(0,0,0,0.8);
            color: #fff;
            left: 0;
            top: 180%;
            padding:10px;
            border-radius: 6px;
            z-index: 555;
            &::after,&::before{
              border: solid transparent;
              content: ' ';
              height: 0;
              bottom: 100%;
              position: absolute;
              width: 0;

            }  
            &::after {
              border-width: 10px;
              border-bottom-color: rgba(0,0,0,0.8);
              opacity: 0.9;
              // background: rgba(0,0,0,0.8);
              left: 45%;
            }

          }
        }
        .li-teams{
          position: relative;
          flex: 8;
          max-width:  270px;
          &:hover{
            .teams-show{
              display: block;
            }
          }
          .teams-text{
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
          .tri-up{
            width: 0;
            height:0;
            border-left:20px solid transparent;
            border-right: 20px solid transparent;
            border-bottom: 20px solid red; 
           }

          .teams-show{
            display: none;
            position: absolute;
            background: rgba(0,0,0,0.8);
            color: #fff;
            left: 0;
            top: 180%;
            padding:10px;
            border-radius: 6px;
            z-index: 555;
            &::after,&::before{
              border: solid transparent;
              content: ' ';
              height: 0;
              bottom: 100%;
              position: absolute;
              width: 0;
            }  
            &::after {
              border-width: 10px;
              border-bottom-color: rgba(0,0,0,0.8);
              opacity: 0.9;
              left: 45%;
            }
          }
        }
        .li-ratio-list{
          max-width: 400px;
          display: flex;
          flex-wrap: wrap;
        }
        .li-ratio{
          // flex: 1;
          margin-right: 20px;
          margin-top: 5px;
          .ratio-item{
            // width: 76px;
            padding: 0 15px;
            height: 30px;
            line-height: 30px;
            display: flex;
            border: 1px solid #6CD590;
            box-shadow: 0px 8px 16px 0px rgba(204, 204, 204, 0.2);
            border-radius: 4px;
            cursor: pointer;
            >div{
              // overflow: hidden;
              // text-overflow:ellipsis;
              // white-space: nowrap;
            }
            >div:last-child{
              padding-right: 0;
            }
            div:nth-child(1){
              // max-width: 40px;
            }
            div:nth-child(2){
              text-align: right;
              margin-left: 10px;

            }
          }
        }
      }
      .lists-title{
        display: flex;
        align-items: center;
        padding: 10px 20px 25px 28px;
        .lists-left{
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        .lists-right{
          margin-left: auto;
          display: flex;
          align-items: center;
          .search{
            width: 97px;
            height: 38px;
            border: 1px solid #001B57;
            line-height: 38px;
            text-align: center;
            margin-left: 13px;
            color: #001B57;
            font-weight: bold;
            cursor: pointer;
            border-radius: 3px;
          }
          
        }
      }
      .lists-table{
        padding: 0px 20px 0px 28px;
        .statusRed{
          color: #ff5e5e;
        }
      }
      .lists-page{
        padding: 0px 20px 0px 28px;
        margin-top: 3px;
      }
    }
  }
  .bet-modal{
    .bet-list{
      margin: 0 40px;
      .ratio-item{
        // max-width: 200px;
        padding: 0 15px;
        height: 30px;
        line-height: 30px;
        display: flex;
        border: 1px solid #6CD590;
        box-shadow: 0px 8px 16px 0px rgba(204, 204, 204, 0.2);
        border-radius: 4px;
        margin: auto;
        >div{
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        div:nth-child(1){
          max-width: 150px;
        }
        div:nth-child(2){
          text-align: right;
          margin-left: auto;
        }
      }
      .list-flex{
        margin-top: 20px;
        display: flex;
        color: #1A1A1A;
        font-size: 12px;
        &.list-flex-top{
          margin-top: 30px;
        }
        .flex-left{
          font-weight: 600;  
        }
        .flex-right{
          margin-left: auto;
          .right-money{
            color: #E43027;
          }
          .right-tip{
            color: #5564F3
          }
        }
      }
      .list-input{
        height: 46px;
        background: #F2F5F7;
        color: #A7BAC6;
        margin-top: 30px;
        .tipText{
          margin-top: 5px;
          color: #E43027;
          font-size: 10px;
        }
        /deep/.el-input{
          height: 100%;
          input{
            width: 100%;
            height: 100%;
            text-align: center;
            color: #A7BAC6;
            font-size: 12.5px;
            background: none;
            outline: none;
            border: none;
          }
          input::-webkit-input-placeholder {
            color: #A7BAC6;
          }
          input:-moz-placeholder {
            color: #A7BAC6;
          }
          input:-ms-input-placeholder {
            color: #A7BAC6;
          }

        }
      }
    }
    .list-btn{
      font-size: 16.5px;
      margin-top: 33px;
      .btn-add{
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #5564F3;
        border-radius: 5px;
        margin: auto;
        color: #fff;
        box-shadow: 0px 13px 19px 0px rgba(85, 100, 243, 0.3);
        cursor: pointer;
      }
    }
  }
  .guessing-show-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 44;

  }
  .guessing-show-item{
    position: fixed;
    width: 380px;
    padding: 30px 60px;
    top: 344px;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 55;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .bet-title{
      font-size: 16px;
      font-weight: bold;
      color: #333;
      text-align: center;
    }
    .bet-list{
      // margin: 0 40px;
      margin-top: 40px;
      .ratio-item{
        // max-width: 200px;
        padding: 0 15px;
        height: 30px;
        line-height: 30px;
        display: flex;
        border: 1px solid #6CD590;
        box-shadow: 0px 8px 16px 0px rgba(204, 204, 204, 0.2);
        border-radius: 4px;
        margin: auto;
        >div{
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        div:nth-child(1){
          max-width: 300px;
        }
        div:nth-child(2){
          text-align: right;
          margin-left: auto;
        }
      }
      .list-flex{
        margin-top: 20px;
        display: flex;
        color: #1A1A1A;
        font-weight: 500;
        font-size: 12px;
        &.list-flex-top{
          margin-top: 30px;
        }
        .flex-right{
          margin-left: auto;
          .right-money{
            color: #E43027;
          }
          .right-tip{
            color: #5564F3
          }
        }
      }
      .list-input{
        height: 46px;
        // background: #F2F5F7;
        border: 1px solid #CCCCCC;
        color: #A7BAC6;
        margin-top: 30px;
        .tipText{
          margin-top: 5px;
          color: #E43027;
          font-size: 10px;
        }
        /deep/.el-input{
          height: 100%;
          input{
            width: 100%;
            height: 100%;
            text-align: center;
            color: #A7BAC6;
            font-size: 12.5px;
            background: none;
            outline: none;
            border: none;
          }
          input::-webkit-input-placeholder {
            color: #A7BAC6;
          }
          input:-moz-placeholder {
            color: #A7BAC6;
          }
          input:-ms-input-placeholder {
            color: #A7BAC6;
          }

        }
      }
    }
    .list-btn{
      font-size: 16.5px;
      margin-top: 33px;
      .btn-add{
        width: 100px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #5564F3;
        border-radius: 5px;
        margin: auto;
        color: #fff;
        box-shadow: 0px 13px 19px 0px rgba(85, 100, 243, 0.3);
        cursor: pointer;
      }
    }
    
  }



}

</style>
