<template>
  <div class="game">
    <!-- 这是游戏陪玩页面 -->
    <div class="game-search">
      <div class="search-bnt">
        <span>游戏类型:</span>
        <div class="bnt-click">
          <div v-for="(item,idx) in bntList" :key="idx" :class="{'click-item':idx==bntsIdx}" @click="handleBntsData(item,idx)">{{item.name}}</div>

        </div>
        <div class="bnt-pages">
          <i class="el-icon-arrow-left" @click="handleGamePre"></i>
          <i class="el-icon-arrow-right pages-right" @click="handleGameNext"></i>
        </div>
      </div>
      <div class="search-other">
        <div class="other-sex">  
          <el-select v-model="gender" size="small" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
  
        </div>
        <div class="other-lgb">
          <span>霖感币：</span>
          <div class="lgb-noe">
            <input type="number" v-model="startPrice" maxlength="5" placeholder="请输入数字"/>
          </div>
          <div class="lgb-bg"></div>
          <div class="lgb-three">
            <input type="number" v-model="endPrice" maxlength="5" placeholder="请输入数字"/>
          </div>
        </div>
        <div class="other-bnt" @click="handleSearchBnt">确认</div>
      </div>
    </div>
    <div class="game-lists"  ref="scrollBox" id="lists-data" @scroll="scrollEvent "> 
      <div  class="lists-data" v-if="lists.length>0">
        <div class="lists-item" v-for="(item,idx) in lists" :key="idx" @click="handleGameDetails(item)" >
          <img v-if="item.backdrop" :src="item.backdrop"/>
          <img v-else-if="!item.backdrop && !item.headImg" src="~@/assets/images/balance/game/noBig.png"/>
          <img v-else :src="item.headImg"/>
          <div class="item-name">
            {{item.nickName || '测试'}}
            <div class="item-lab aa" v-if="item.voiceIntroduce">
              <div class="cc" v-if="item.isPlaying" @click.stop="stop(item,idx)">
                <img src="~@/assets/images/balance/laba.gif" />

              </div>
              <div class="bb" v-if="!item.isPlaying" @click.stop="handlePayAudio(item,idx)">
                <img src="~@/assets/images/balance/laba.png" />

              </div>
            </div>
          </div>
          <div class="item-lgb">
            <img src="~@/assets/images/balance/game/lgIcon.png"/>
            <div>{{item.promotionPrice || 0}} 币 / {{item.unitType==1?'小时':'局'}}</div>
          </div>
        </div>
        <div class="audio-url">
          <audio controls="controls" ref="audio"   
            @ended="overAudio"
            @pause="onPause"
            @play="onPlay">
            <source :src="audioUrl" type="audio/ogg" />
          </audio>
        </div>
      </div>
      <Nodata v-else :noWidth="'364px'"  :noHeight="'340px'" ></Nodata>
    </div>
  </div>
</template>
<script>

import { getClientPlayerList } from "@/api/balance/game";
import { getGame } from "@/api/userInfo";
import Nodata from "@/components/nodata";
export default {
  name: "game",
  components: { Nodata },
  data() {
    return{
      tipWidth: '600px',
      tipHeight: '600px',
      isPlaying: false,
      bntList: [
        {
          name: '全部'
        }
      ],
      bntsIdx: 0,
      value: '',
      options: [
        {
          id: '',
          name: '不限性别'
        },
        {
          id: 0,
          name: '男'
        },
        {
          id: 1,
          name: '女'
        },
      ],
      lists: [],
      audioUrl: '',
      audio: null,
      gameId: '', // 游戏id保存
      gender: '', // 保存性别
      startPrice: '', // 开始价格
      endPrice: '', // 结束价格
      payShow: false,
      totalNum: 0,
      loading: false,
      active: 1,
      pageSize: 10,
      gameNum: 1,
      gameSize: 8,
      gameTatal: 0,
    }
  },
  computed:{
  },
  methods: {
    // 游戏下一页
    handleGameNext(){
      if((this.gameNum*this.gameSize) < this.gameTatal) {
        this.gameNum++
        this.handleGetGame()
      }
    },
    // 游戏上一页
    handleGamePre(){
      if(this.gameNum == 1) {
        this.gameNum = 1
      }else {
        this.gameNum--
        this.handleGetGame()

      }

    },
    // 滚动加载动态
    scrollEvent(e) {
      let outerClientHeight = this.$refs.scrollBox.clientHeight; // 外层盒子可视高度
      let outerScrollTop = this.$refs.scrollBox.scrollTop; // 外层盒子卷去的高度
      let innerScrollHeight = document.querySelector("#lists-data").scrollHeight; // 内层盒子全部的高度
      if (innerScrollHeight - outerScrollTop - outerClientHeight <= 50) {
        if (
          this.lists.length > this.totalNum ||
          this.totalNum == this.lists.length
        ) {
        } else {
          if (this.loading) {
            this.loading = false;
            this.active = this.active + 1;
            this.loadMore();
          }
        }
      }
    },
    loadMore() {
      this.handleClientPlayerList();
    },
    handleGetGame() {
      
      let params = {
        pageNum: this.gameNum,
        pageSize: this.gameSize
      }
      getGame(params).then(res => {
        if (res.code == 200) {
          this.bntList = res.rows
          this.gameTatal = res.total
        }
      })
    },
    onPlay(item) {

    },
    onPause() {

    },
    overAudio() {
      this.handleCloseAydio()

    },
    // 播放语音
    handlePayAudio(item,idx) {   
      this.$refs.audio.src = item.voiceIntroduce;
      this.$refs.audio.play()
      for(let i in this.lists ) {
        this.lists[i].isPlaying
        if(this.lists[i].lgUserId == item.lgUserId) {
          this.lists[i].isPlaying = true
        }else {
          this.lists[i].isPlaying = false

        }
      }
      this.$forceUpdate()
    },
    
    stop(item,idx){
      this.lists[idx].isPlaying = false
      this.$forceUpdate()
      this.handleCloseAydio()
    },
    // 列表
    handleClientPlayerList() {
      let params = {
        gameId: this.gameId,
        gender: this.gender,
        startPrice: this.startPrice,
        endPrice: this.endPrice,
        pageSize: this.pageSize,
        pageNum: this.active
      }
      getClientPlayerList(params).then(res => {
        if(res.code == 200) {
          // this.lists = res.rows
          this.totalNum = res.total;
          this.lists = this.lists.concat(res.rows);
          this.loading = this.active < res.total;
          for(let i in this.lists) {
            this.lists[i].isPlaying = false
          }
        }
      })

    },
    // 跳转详情页面
    handleGameDetails(item) {
      this.$router.push({path: 'Details',query:{ id: item.lgUserId,gameId:this.gameId}});
    },
    // 搜索按钮
    handleSearchBnt() {
      this.active = 1
      this.lists = []
      this.handleClientPlayerList()
    },
    // 游戏类型按钮
    handleBntsData(item,idx) {
      this.bntsIdx = idx
      this.active = 1
       this.lists = []
      if(item.id) {
        this.gameId = item.id
      }else {
        this.gameId = ''

      }
      this.handleClientPlayerList()
    },
    handleCloseAydio() {
      if(this.$refs.audio) {
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
        this.$refs.audio.src = ''
      }
    }
  },
  created() {
    this.handleGetGame()
  },
  mounted() {
    this.active =  1;
    this.lists = []
    this.handleClientPlayerList()
    // 监听外部盒子的滚动事件
    this.$refs.scrollBox.addEventListener("scroll", this.scrollEvent);
  },
  beforeDestroy() {
    this.handleCloseAydio()
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
