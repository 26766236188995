<template>
  <div class="details">
    <div class="details-back" @click="handleBackData">
      <img src="~@/assets/images/balance/game/back.png"/>
    </div>
    <div class="details-left">
      <div class="left-imgs">
        <div class="imgs-big">
          <img :src="imgSShow" v-if="imgSShow"/>
          <img class="big-no" src="~@/assets/images/balance/game/noBig.png" v-else/>
        </div>
        <div class="imgs-list" v-if="userInfo.userPhotos.length>0">
          <div class="list-pre" @click="handlePretData"><i  class="el-icon-arrow-up"></i></div>
          <div class="list-data"  ref="listImg">
           
            <div v-for="(item,idx) in userInfo.userPhotos" :key="idx" :class="{'imgs-show': idx == imgShow}" @click="handleImgsData(item,idx)" :style="{transform: `translate3d(0px, -${heightShow}px, 0px),'transition': 'all 0.5s'` }">
              <img v-if="userInfo.userPhotos.length>0" :src="item">
              <img class="no-img" v-else src="~@/assets/images/balance/game/noImg.png">
            </div>
          </div>
          <div class="list-next" @click="handleNextData"><i class="el-icon-arrow-down"></i></div>
        </div>
      </div>
      <div class="left-name">
        <div class="name-text">{{userInfo.nickName}}</div>
        <img class="name-lgb" src="~@/assets/images/balance/game/lgIcon.png"/>
        <img class="name-sex" v-if="userInfo.gender == 1" src="~@/assets/images/balance/user/sex1.png"/>
        <img class="name-sex" v-else src="~@/assets/images/balance/user/sex.png"/>
      </div>
      <div class="left-type">
        <div class="type-data">
          <img class="data-icon data-age"  src="~@/assets/images/balance/game/08.png"/>
          <div class="data-text">
            <span>年龄：</span>
            {{userAge}}岁
          </div>
        </div>
        <!-- <div class="type-data">
          <img class="data-icon " src="~@/assets/images/balance/game/07.png"/>
          <div class="data-text"><span>年龄：</span>18岁</div>
        </div> -->
        <div class="type-data">
          <img class="data-icon " src="~@/assets/images/balance/game/07.png"/>
          <div class="data-text"><span>星座：</span>{{userInfo.sign || '---'}}</div>
        </div>
        <div class="type-data">
          <img class="data-icon " src="~@/assets/images/balance/game/06.png"/>
          <div class="data-text"><span>所在地：</span>{{userInfo.address || '---'}}</div>
        </div>
        <div class="type-data">
          <img class="data-icon " src="~@/assets/images/balance/game/05.png"/>
          <div class="data-text"><span>我的标签：</span>
            <span v-if="userInfo.userTag.length > 0">
              <span class="text-tag" v-for="(item,idx) in userInfo.userTag" :key="idx">{{item}}</span>

            </span>
            <span class="text-tag" v-else>---</span>
              
          </div>
        </div>
        <div class="type-state">
          <img class="data-icon " src="~@/assets/images/balance/game/04.png"/>
          <div class="data-text"><span>个人说明</span>
            <div>{{userInfo.signature || '---'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="details-right">
      <div class="right-tab">
        <div class="tab-data" :class="{'tab-show': idx == tabIdx}" @click="handleTabData(item,idx)" v-for="(item,idx) in tabList" :key="idx">{{item.name}}</div>
      </div>
      <div class="right-all">
        <div class="right-head">
          <div class="head-img">
            <img v-if="dataObj.logo" :src="dataObj.logo">
            <img class="no-big" v-else src="~@/assets/images/balance/game/noBig.png">
          </div>
          <div class="head-flex">
            <div class="head-item">
              <div class="item-lgb">
                <span class="lgb-new">{{dataObj.promotionPrice || 0}}</span> 霖感币/{{dataObj.unitType == 1?'小时':'局'}}
                <!-- <span class="lgb-old">{{dataObj.price || 0}}霖感币</span> -->
              </div>
              <div class="item-tag">{{dataObj.danGrading}}</div>
              <div class="item-num">接单数量：{{dataObj.orderCount || 0}}次</div>
              <div class="item-input">
                  <el-input-number v-model="num" size="mini" @change="handleNumChange" :min="1" :max="23" label=""></el-input-number>
              </div>
            </div>
            <div class="head-bnts">
              <div class="head-message" v-if="userInfo.lgUserId != lgUserId" @click="handlePlayMessage(userInfo)">聊一聊</div>
              <div class="head-bnt" @click="handlePlayOpenData">立即下单</div>
            </div>

          </div>
        </div>
        <div class="right-content">
          <div class="content-service" >
            <div class="service-title">
              <img src="~@/assets/images/balance/game/03.png"/>
              <div>服务介绍</div>
            </div>
            <div class="service-text">{{dataObj.explainText}}</div>
          </div>
          <div class="content-service skill-all" >
            <div class="service-title">
              <img src="~@/assets/images/balance/game/02.png"/>
              <div>技能信息</div>
            </div>
            <div class="service-skill">
              <!-- <div class="skill-rank">可接段位：黑铁，青铜，白银，黄金，铂金</div>
              <div class="skill-location">擅长位置：中单，adc，辅助</div> -->
              <div class="skill-district" v-for="(item,idx) in dataObj.gameParams" :key="idx">
                <div>{{item.name}}：</div>
                <div>{{item.value}}</div>
              </div>
            </div>
          </div>
          <div class="content-service strength-all">
            <div class="service-title">
              <img src="~@/assets/images/balance/game/01.png"/>
              <div>实力认证</div>
            </div>
            <div class="service-strength">
              <div class="strength-list" >
                <div class="list-img" v-for="(item,idx) in dataObj.strengthImgShow" :key="idx" @click="handleOpenImg(item)">
                  <img :src="item"/>
                </div>
              </div>
              <!-- <div class="strength-pages">
                <div>
                  <i class="el-icon-arrow-left"></i>
                </div>
                <div>
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div> -->
            </div>
          </div>
        </div>

      </div>
      <div class="right-foot"></div>
    </div>
    <!-- 订单弹窗 -->
    <div class="order-tip-bg" v-show="orderShow"></div>
    <div class="order-tip" v-show="orderShow">
      <div class="tip-close" @click="handleTipCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-title">订单信息</div>
      <div class="tip-data">
        <div class="data-flex">
          <div>服务类型</div>
          <div class="flex-right">{{dataObj.name}}</div>
        </div>
        <div class="data-flex">
          <div>单      价</div>
          <div class="flex-right">{{dataObj.promotionPrice || 0}}霖感币/{{dataObj.unitType == 1?'小时':'局'}}</div>
        </div>
        <div class="data-flex">
          <div>数      量</div>
          <div class="flex-right">{{num}}</div>
        </div>
        <div class="data-flex ">
          <div>实付金额</div>
          <div class="flex-right flex-monry">{{newMoney || 0}}霖感币</div>
        </div>
      </div>
      <p class="tip-pay-type">支付方式</p>
      <div class="tip-bnt">
        <div class="bnt-lgb" v-if="lgbObj.lgMoney > (newMoney) || lgbObj.lgMoney == (newMoney)" @click="handleQRcodeData(1)">霖感币</div>
        <div class="lgb-hide" v-else >霖感币</div>
        <div class="bnt-wechat" @click="handleQRcodeData(2)">微信</div>
        <div class="bnt-alipay" @click="handleQRcodeData(3)">支付宝</div>
      </div>
    </div>
    <!-- 支付弹窗 -->
    <div class="order-tip-bg" v-show="playShow"></div>
    <div class="order-tip" v-show="playShow">
      <div class="tip-close" @click="handlePlayCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="play-title">
        <div class="title-name">{{playTitle}}</div>
        <div class="title-money">
          {{palyLabel}}
          <span class="money-icon">￥</span> <span class="money-num">{{newMoney || 0}}</span>
        </div>
      </div>
      <!-- 霖感币支付 -->
      <div class="tip-lgb" v-if="playIdx == 1">
        <div class="lgb-input">
          <input type="password" maxlength="6" v-model="password" placeholder="请输入支付密码"/>
        </div>
        <div class="lgb-add-input"  v-loading="orderLoading" @click="handlCreateOrder">确认支付</div>
      </div>
      <!-- 二维码支付 -->
      <div class="tip-qr-code" v-else>
        <div class="qr-img">
          <img :src="imgCode"/>
        </div>
        <div class="qr-text">严禁利用收款吗进行诈骗，发现后立即通知公安机关</div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
     <!-- 信息弹窗 IM -->
  </div>
</template>

<script>
import { getClientPlayerList,getPlayerInfo,payOrder,createOrder,pollingPlayWithOrder } from "@/api/balance/game";
import { getWalletInfo } from "@/api/userInfo";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'

export default {
  name: "gameDetails",
  data() {
    return{
      imgShow: 99,
      orderLoading: false,
      dialogImageUrl: '',
      dialogVisible: false,
      tabList: [
        {
          id: 1,name: '英雄联盟',
        },
        {
          id: 2,name: '绝地求生',
        },
        {
          id: 3,name: '王者荣耀',
        },
        {
          id: 4,name: '云顶之巅',
        },
        {
          id: 5,name: '云顶之弈',
        },
        {
          id: 6,name: '和平精英',
        },
      ],
      tabIdx: 0,
      num: 1,
      orderShow: false,
      playShow: false,
      palyLabel: '支付金额：',
      playTitle: '霖感币支付',
      playIdx: 0,
      userId: '',
      userInfo: {
        userPhotos: ['','','','',''],
        userTag: [],
      },
      dataObj: {
        strengthImgShow: [],
      },
      lgUserId: '',
      password:'',
      imgSShow: '',
      userAge: '18',
      gameId: '', // 游戏ID
      imgCode: '',
      payNumber: '',
      lgbObj:{
        lgMoney: 0,
        incomeMoney: 0,
      },
      newMoney: 0,
      heightShow: 0,
      coinNUm: 20
    }
  },
  computed:{
  },
  methods: {  
    // 打开聊天弹窗
    handlePlayMessage(data) {
      this.$store.state.gameType = true
      this.$parent.handleOpenIm(data);
      if(!sessionStorage.IMShow) {
      }

    },
    // 获取余额
    handleWalletInfo() {
      getWalletInfo({lgUserId:this.lgUserId}).then(res => {
        if (res.code == 200) {
          if(res.data) {
            this.lgbObj = res.data; 

          }
        }
      });
    }, 
    // 清除轮询
    handleClearTimeout() {
      this.handlePlayCloseData()
      if(this.timer) {
        clearTimeout(this.timer);　　// 清除定时器
        this.timer = null;
        this.$once('hook:beforeDestroy', () => {   // 清除定时器   
          clearTimeout(this.timer);   
          this.timer = null;                                       
        })
      }　

    },
    // 轮询
    pollingPlayWithOrder() {
      this.coinNUm--
      let _this = this
      pollingPlayWithOrder({orderNumber: this.payNumber}).then(res => {
        if (res.code == 200) {
          _this.timer = setTimeout(()=>{
            if(_this.coinNUm<1) {
              if(res.data == 2){ 
                _this.$message({
                    message: res.msg || '支付成功!',
                    type: 'success',
                    customClass: 2000
                });
              }else {
                _this.$message.error('支付超时!');
              }
              _this.coinNUm = 20 
              _this.timer = null
              _this.handlePlayCloseData()
              _this.handleClearTimeout()
              return true
            }else {
              if(res.data == 2) {
                _this.$message({
                    message: res.msg,
                    type: 'success',
                    customClass: 2000
                });
                _this.coinNUm = 20 
                _this.timer = null
                _this.handlePlayCloseData()
                _this.handleClearTimeout()
                return true

              }

            }
           _this.pollingPlayWithOrder()
          },3000)
        }else {
          _this.$message.error(res.msg || '下单失败!');
          _this.coinNUm = 20 
          _this.timer = null
          _this.handleClearTimeout()
        }
      })

    },
    getage(birthday) {
      var birthdays = new Date(birthday.replace(/-/g, "/"));
      var d = new Date();
      var age =
        d.getFullYear() -
        birthdays.getFullYear() -
        (d.getMonth() < birthdays.getMonth() ||
        (d.getMonth() == birthdays.getMonth() &&
          d.getDate() < birthdays.getDate())
          ? 1
          : 0);
      this.userAge = age;
    },
    // 上张
    handlePretData() {
      let imgDow =  this.$refs.listImg
      let length = imgDow.offsetHeight
      if(this.heightShow>0) {
        this.heightShow = 0
      }
    },
    // 下张
    handleNextData() {
      let imgDow =  this.$refs.listImg
      let length = imgDow.offsetHeight
      if(this.heightShow > 0){
        if(this.heightShow>length){
          this.heightShow = this.heightShow*1 
          return
        }
      }else{
        if(this.userInfo.userPhotos.length > 3) {
          this.heightShow = length+ 23

        }
      }
    },
    // 选择照片
    handleImgsData(item,idx) {
      this.imgSShow = item
      this.imgShow = idx
    },
    // 支付订单
    handlPayOrder(item) {
      let password = ''
      if(this.playIdx == 1) {
        password = this.password
      } else {
        password = ''
      }
      let parmas = {
        payType: this.playIdx,
        orderNumber: item.orderNumber,
        password: password,
      }
      payOrder({data:encryptByDESModeCBCUtf8to64(JSON.stringify(parmas))}).then(res => {
        if (res.code == 200) {
          if(this.playIdx == 1) {
            this.$message({
                message: res.msg,
                type: 'success',
                customClass: 2000
            });
          } else {
            this.payNumber = res.data.orderNumber
            this.imgCode = res.data.imgUrl
            this.pollingPlayWithOrder()
          }
          this.password = ''
        }else {
          this.$message.error(res.msg || '下单失败!');
        }
        if(this.playIdx == 1) {
          // password = this.password
          this.handlePlayCloseData()
        }
        this.orderLoading = false
      }).catch(res=>{
        // this.$message.error( '下单失败!');
        // this.handlePlayCloseData()
        this.orderLoading = false
      })
    },
    // 创建订单
    handlCreateOrder() {
      if(this.playIdx == 1) {
        if(!this.password) {
          this.$message.error('请输入支付密码!');
          return
        }

      }
      this.orderLoading = true
      let params = {
        payType: this.playIdx,
        fromLgUserId: this.lgUserId,
        toLgUserId: this.userId,
        number: this.num,
        gameServiceId: this.dataObj.serviceId,
      }
      createOrder({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if (res.code == 200) {
          
          this.handlPayOrder(res.data)

        }else {
          this.$message.error(res.msg || '下单失败!');
        }
        this.orderLoading = false
      })

    },
    // 查看图片
    handleOpenImg(item) {
      this.dialogVisible = true
      this.dialogImageUrl = item
    },
    handleTipCloseData() {
      this.orderShow = false
    },
    // 返回
    handleBackData() {
      this.$router.go(-1)
    },
    handleTabData(item,idx) {
      this.tabIdx = idx
      this.dataObj = item
      if(typeof(this.dataObj.gameParams) == 'string') {
        this.dataObj.gameParams = JSON.parse(this.dataObj.gameParams)
      }
      if(this.dataObj.strengthImg) {
        this.dataObj.strengthImgShow = this.dataObj.strengthImg.split(',')
      }
    },
    handleNumChange(value) {
    },

    // 1微信 2支付宝
    handleQRcodeData(num) {
      this.playIdx = num
      this.playShow = true
      this.orderShow = false
      this.password = ''
       if(num==1) {
        this.playTitle = '霖感币支付'
        this.palyLabel = '支付金额：'

      } else {
        this.playTitle = '扫码支付'
        this.palyLabel = '应付金额：'
        this.handlCreateOrder()

      }
    },
    // 打开支付弹窗
    handlePlayOpenData() {
      this.orderShow = true
      this.newMoney = (this.dataObj.promotionPrice*this.num).toFixed(2)
      this.handleWalletInfo()

    },
    // 关闭支付弹窗
    handlePlayCloseData() {
      this.playShow = false
      this.playIdx == 1

    },
    // 查询详情
    getPlayerInfo(userId) {
      getPlayerInfo({lgUserId:userId}).then(res => {
        if (res.code == 200) {
          this.userInfo = res.data
          this.imgSShow = this.userInfo.headImg 
          if(this.userInfo.birthday) {
            this.userInfo.birthday = this.$utils.dateFormat(this.userInfo.birthday, 'yyyy-MM-dd')
            this.getage(this.userInfo.birthday)
            let time = this.$utils.dateFormat(this.userInfo.birthday, 'MM-dd')
            let arr = time.split('-')
          }
          this.tabList = this.userInfo.gameList
          for(let i in this.tabList) {
            if(this.tabList[i].gameId == this.gameId) {
              this.dataObj = this.tabList[i]
              this.tabIdx = i
            }else{
              this.dataObj = this.userInfo.gameList[0]
            } 
          }
          if(this.dataObj.gameParams) {
           this.dataObj.gameParams = JSON.parse(this.dataObj.gameParams)

          }
          if(this.dataObj.strengthImg) {
            this.dataObj.strengthImgShow = this.dataObj.strengthImg.split(',')
          }

        }
      })
    },
  },
  created() {},
  mounted() {
    this.lgUserId = sessionStorage.lgUserId
    this.userId = this.$route.query.id
    this.gameId = this.$route.query.gameId || ''
    this.getPlayerInfo(this.userId)
  },
  beforeDestroy(){ 
    this.handleClearTimeout()
  },
  destroyed() {
    this.handleClearTimeout()
  }
};
</script>
<style lang="less">
@import "./details.less";
</style>
