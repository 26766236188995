<template>
  <div class="answer">
    <div class="answer-lists"  v-if="lists.length>0"> 
      <div  class="lists-data" v-for="(item,idx) in lists" :key="idx" >
        <div class="lists-item">
          <div class="item-mag"> 
            <div class="mag-tilte">{{item.multiple==1?'One':item.multiple==2?'Two':item.multiple==3?'Three':item.multiple==4?'Four':item.multiple==5?'Five':item.multiple==6?'Six':item.multiple==7?'Seven':item.multiple==8?'Eight':item.multiple==9?'Nine':item.multiple==10?'Ten':item.multiple==11?'Eleven':item.multiple==12?'Twelve':'More'}} ticket/reward×{{item.multiple || 0}}</div>
            <div class="mag-flex">
              <div class="flex-left">
                <img src="~@/assets/images/balance/answer/icon.png">
              </div>
              <div class="flex-right">{{item.name}}</div>
            </div>
            <ul class="mag-ul">
              <li>消耗{{item.price}}网费</li>
              <li>奖励×{{item.multiple}}</li>
            </ul>
            <div class="mag-foot mag-img" >
              <img class="foot-img" src="~@/assets/images/balance/answer/rank.png">
            </div>
            <div class="mag-foot mag-bnt" v-if="!hasAnswer" @click="handleAnswerOpen(item,idx,1)">
              立即答题
            </div>
            <div class="mag-foot mag-foot-hide " @click="handleAnswerOpen(item,idx,2)"  v-else >
              立即答题
            </div>
          </div>
        </div>
      </div>
    </div>
    <Nodata v-else :noWidth="'364px'"  :noHeight="'340px'" ></Nodata>

    <div class="answer-rule">
      <img @click="handleruleOpen" src="~@/assets/images/balance/answer/rule.png">
    </div>
    <div class="answer-tip-bg" v-show="tipShowBg"></div>
    <div class="answer-tip" v-show="tipShow">
      <div class="tip-close" @click="handleTipCloseData" 
      style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="add-tip" v-if="tipType == 1">
        <div class="add-title">
          <div class="title-left"><i class="el-icon-info"> </i></div>
          <div class="title-right">提示：消耗{{obj.price}}网费参与答题</div>
        </div>
        <div class="bnt-flex">
          <div class="bnt-left" @click="handleTipCloseData">返回</div>
          <div class="bnt-right" @click="handleStartData">确认</div>
        </div>
      </div>
      <div class="paper-tip" v-if="tipType == 2">
        <div class="paper-time">
          <div>#答题倒计时</div> 
          <count-down  class="right-time"
            :required='false'
            :minutesTxt="':'" :secondsTxt="''"
            v-on:start_callback="countDownS_cb()" 
            v-on:end_callback="countDownE_cb()" 
            :startTime="startTime" 
            :endTime="endTime" >
            </count-down>
        </div>
        <div class="paper-title">全部答对，奖励*{{obj.multiple}}倍</div>
        <div  class="paper-content">
          <div class="content-text">
            <div class="text-num">活动第{{lookIdx+1}}/{{arr.length}}题目</div>
            <img class="text-img" src="~@/assets/images/balance/answer/rank.png">
          </div>
          <div class="content-item">
            <div class="item-name">
             {{lookIdx+1}}、{{lookObj.content}} ({{lookObj.type == 1?'单选':'多选'}})
            </div>
            <ul class="item-list">
              <li  :class="{'list-li-show':item.selectd == true }" v-for="(item,idx) in lookObj.optionsShow" :key="idx" @click="handleSelectdData(item,idx,lookObj)">
                <div class="list-li-text">
                  <span> </span> <span> {{item.v}}</span>
                </div>
                <img v-if="item.selectd == true"   src="~@/assets/images/balance/answer/selectd.png"/>
              </li>
            </ul>
          </div>
        </div>
        <div class="paper-add" @click="handleNextData" v-if="!bntStatus">下一题</div>
        <div class="paper-add" @click="handleAddData" v-else>提交</div>
      </div>
    </div>
    <div  class="success-tip" v-show="successShow">
      <div class="add-close" @click="handleSuccessClose" 
      style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="add-bg">
        <div class="bg-head">
          <div class="head-grade">{{lookItem.score || 0}}分</div>
          <div class="head-text">本次使用{{lookItem.multiple}}张门票，获得<span>{{lookItem.fee}}元</span>赠送网费</div>
        </div>
        <div class="bg-list">
          <div class="list-th">
            <div>分值</div>
            <div>赠送网费</div>
          </div>
          <div class="list-td" v-for="(item,idx) in lookItem.answerScore" :key="idx">
            <div >{{item.scoreBlock}}</div>
            <div>{{item.fee}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="close-tip-bg" v-show="closeShow"></div>
    <div class="close-tip" v-show="closeShow">
      <div class="tip-close" @click="handleAnswerBlock" 
      style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="add-tip">
        <div class="add-title">
          <div class="title-left"><i class="el-icon-info"> </i></div>
          <div class="title-right">放弃本次答题，消耗的网费不退回确定放弃吗？</div>
        </div>
        <div class="bnt-flex">
          <div class="bnt-left"  @click="handleAnswerBlock">返回</div>
          <div class="bnt-right" @click="handleCloseAnswer">确认</div>
        </div>
      </div>
    </div>
    <div class="rule-tip-bg" v-show="ruleShow"></div>
    <div  class="rule-tip" v-show="ruleShow">
      <div class="add-close" @click="handleRuleClose" 
      style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="rule-item">
        <div class="rule-icon">
          <img src="~@/assets/images/balance/answer/ruleShow.png">
        </div>
        <ul class="rule-list">
          <li v-for="(item,idx) in ruleList" :key="idx">
            {{item.num}}
            {{item.name}}
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>
<script>

import CountDown from 'vue2-countdown'
import { getTickets,buyTicket,submitAnswerSheet } from "@/api/balance/answer";
import { getGame } from "@/api/userInfo";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC }  from '@/common/utils/des'
import Nodata from "@/components/nodata";
export default {
  name: "answer",
  data() {
    return{
      ruleShow: false,
      startTime:  '',
      endTime: '', 
      bntStatus: false,
      successShow:  false,
      closeShow: false,
      tipType: 1,
      tipShowBg: false,
      tipShow: false, 
      lists: [], // 保存 列表数据
      hasAnswer: false,  // 判断是否可以答题
      obj: {}, 
      topicObj: {},  // 保存全部数据
      arr:[], // 保存总题目
      lookObj: { // 保存当前的题目
        optionsShow: []
      },
      lookIdx: 0, // 保存题目的所在的位置
      lookItem: {}, // 提交后保存数据
      addType: 1,
      ruleList: [
        {
          num: '1.',
          name: '每人每日享有1次答题次数，答题次数将在每日0点刷新',
        },
        {
          num: '2.',
          name: '共20道选择题（含单选及多选），会员需在5分钟内完成答题，倒计时结束后将自动提交',
        },
        {
          num: '3.',
          name: '每题分值为5分，总分100分，5~45分奖励为4网费；50~70分奖励为6网费；75~95分奖励为7.5网费；100分奖励为10网费',
        },
        {
          num: '4.',
          name: '会员需购买答题门票，门票价格为5元网费/张，会员可自行选择单次购买单张或多张门票，购买多张门票奖励将乘以门票张数的倍率结算，例如购买5张门票并答题获得100分，将获得10*5共50元网费奖励',
        },
        {
          num: '5.',
          name: '答题过程中请勿关闭答题页面，中途退出答题或倒计时结束都将视为答题结束，门票作为消耗品将不会返还',
        },
      ]
      
    }
  },
  components: { Nodata, CountDown},
  computed:{
  },
  methods: {
    handleruleOpen() {
      this.ruleShow = true

    },
    handleRuleClose() {
      this.ruleShow = false
    },
    countDownS_cb(){},
    countDownE_cb(){
      this.addType = 1
      this.handleAddData()
    },
    // 提交答案
    handleAddData() {
      let str = ''
      let lists = this.arr[this.arr.length-1].optionsShow 
      for(let key in lists) {
        if(lists[key].selectd == true) {
          str += lists[key].k 
        }
      }
      if(str) {
        this.arr[this.arr.length-1].str = this.arr[this.arr.length-1].id + '#' + str
      }
      let params = {
        recordId: this.topicObj.id,
        type: this.addType,
        answers: '',
      }
      for(let i in this.arr)  {
        if(this.arr[i].str){
          params.answers += this.arr[i].str+','
        }
      }
      params.answers=params.answers.substring(0,params.answers.length-1)
      submitAnswerSheet({data:encryptByDESModeCBCUtf8to64(JSON.stringify(params))}).then(res => {
        if(res.code == 200) {
          this.closeShow = false
          this.tipShow = false
          this.tipShowBg = true
          this.successShow = true
          this.lookItem = res.data
          this.hasAnswer = true
        }else{
          this.allClose()
          this.$message.error(res.msg || '提交失败!');
        }
      })
    },
    // 下一题
    handleNextData() {
      let str =  '' 
      let lists = this.lookObj.optionsShow
      
      for(let i in lists) {
        if(lists[i].selectd == true) {
          str += lists[i].k 

        }
      }
      if(str) {
        this.lookObj.str= this.lookObj.id +'#'+str
      }
      this.lookIdx = this.lookIdx*1+1
      this.lookObj = this.arr[this.lookIdx]
      if(this.lookIdx == this.arr.length-1) {
        this.lookObj.optionsShow = JSON.parse(this.lookObj.options)
        this.lookIdx = this.arr.length-1
        this.bntStatus = true
        return
      }else{
        this.lookObj.optionsShow = JSON.parse(this.lookObj.options)

      }
      this.$forceUpdate();
    },
    // 选中
    handleSelectdData(item,idx,lookObj) {
      if(lookObj.type == 1) {
        for(let i in this.lookObj.optionsShow) {
          if( this.lookObj.optionsShow[i].k == item.k) {
            this.lookObj.optionsShow[i].selectd = true
          }else{
            this.lookObj.optionsShow[i].selectd = false
          }
        }
      }else{
        this.lookObj.optionsShow[idx].selectd = !this.lookObj.optionsShow[idx].selectd 

      }
      this.$forceUpdate();
    },
    // 关闭全部弹窗 
    allClose() {
      this.tipType = 1
      this.addType = 1
      this.successShow = false
      this.closeShow = false
      this.tipType = false
      this.tipShowBg = false
      this.tipShow = false
      this.bntStatus = false
    },
    // 确认答题
    handleStartData(){
      let parmas = {
        lgUserId: sessionStorage.lgUserId,
        ticketId: this.obj.id,
        shopName: sessionStorage.shopName
      }
      buyTicket({data:encryptByDESModeCBCUtf8to64(JSON.stringify(parmas))}).then(res => {
        if(res.code == 200) { 
          this.topicObj = res.data
          this.arr = this.topicObj.questions 
          this.lookObj = this.arr[0]
          this.startTime = new Date().getTime()
          this.endTime = (new Date(this.topicObj.endTime).getTime())
          this.lookObj.optionsShow = JSON.parse(this.lookObj.options)
          for(let i in this.lookObj.optionsShow) {
            this.lookObj.optionsShow[i].selectd = false
          }
          this.lookIdx = 0
          this.tipType  = 2
          this.tipShowBg =true
          this.tipShow =true
        }else{
          this.allClose()
          this.$message.error(res.msg || '答题失败!');
        }
      })
    },
    // 打开开始答题确认弹窗
    handleAnswerOpen(item,idx,status) {
      if(status == 2) {
        this.$message.error('今天答题次数已用完!');
        return
      }
      this.tipType  = 1
      this.tipShowBg =true
      this.tipShow =true
      this.obj= item
    },
    handleSuccessClose()  {
      this.allClose()
      this.startTime = ''
      this.endTime = ''
      
    },
    handleAnswerBlock() {
      this.closeShow = false

    },
    // 取消关闭 答题
    handleCloseAnswer() {
      this.addType = 2
      this.handleAddData()
    },
    // 返回答题
    handleBlackData() {
      this.allClose()
    },
    // 关闭弹出
    handleTipCloseData() {
      if(this.tipType == 2) {
        this.closeShow = true
      }else{
        
        this.allClose()
      }
    },
    handelgetTickets() {
      getTickets({lgUserId: sessionStorage.lgUserId}).then(res => {
        this.hasAnswer = res.data.hasAnswer
        this.lists = res.data.tickets
      })
    },
  },
  created() {
  },
  mounted() {
    this.handelgetTickets()
  },
  beforeDestroy() {
  }
};
</script>
<style lang="scss" scoped>
@import "./index.less";

</style>
