<template>
  <div class="balance">
    <div class="balance-tab" style="-webkit-app-region: drag;">
      <div class="tab-top">
        <img class="top-left" src="~@/assets/images/balance/lgLog.png"/>
        <div class="top-right" >
          <div class="right-item" style="-webkit-app-region: no-drag;" :class="{'tabShow':item.id===$store.state.bntType}" v-for="(item,idx) in tab1" :key="idx" @click="handleTabData(item,idx)" >
            <img v-if="item.imgUrl" :src="item.imgUrl"/>
            <div class="tab-new" v-if="item.id == 699">
              <img src="~@/assets/images/balance/new.png"/>
            </div>
            <div v-if="item.id == 4 && $store.state.messageShow" class="tab-show-bg"></div>
            <div class="tab-call" style="-webkit-app-region: no-drag;" v-show="item.id == 2">
              <div @click="handleCallData(1)">呼叫网管 </div>
              <div @click="handleCallData(2)">发送消息</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-bottom">
        <div :class="{'bottomShow':item.id == $store.state.bntType}" style="-webkit-app-region: no-drag;" v-for="(item,idx) in tab2" :key="idx" @click="handleTabData(item,idx)">
            {{item.name}}
            <div class="tab-guessing" style="-webkit-app-region: no-drag;" v-if="item.id == 8 && guessingShow">
              <div @click="handleGuessingData(1)" :class="{'guessing-item':guessingtype == 1}">竞猜活动 </div>
              <div @click="handleGuessingData(2)" :class="{'guessing-item':guessingtype == 2}">答题活动</div>
            </div>
        </div>
      </div>
    </div>
    <div
      class="close"
      @click="closeWin"
      style="-webkit-app-region: no-drag;"
    ></div>
    <div class="balance-cant">
      <router-view ref="Childmain" />
     </div>
    <!-- 发送消息 -->
    <div class="message-tip-bg" v-show="messagePhone"></div>
    <div class="message-tip-body" v-show="messagePhone">
      <div class="tip-close" @click="handleHeadCloseData"  style="-webkit-app-region: no-drag;">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="message-title">发送消息</div>
      <div class="message-tip">
        <div class="tip-input">
          <el-input
            type="textarea"
            maxlength="50"
            rows="4"
            placeholder="请输入内容"
            v-model="messageForm.message">
          </el-input>
        </div>
      </div>

      <div class="tip-bnt">
        <!-- <div class="bnt-upload">上传图片</div> -->
        <div class="bnt-add" @click="handleMessageAdd">发送</div>
      </div>
    </div>
    <Messaging  ref="messaging" ></Messaging>
    <div class="register-tip" v-show="registerShow">
      <div class="register-bg" >
        <div class="register-all">
          <Register ref="registerChild"></Register>
        </div>
        <div class="register-close" style="-webkit-app-region: no-drag;" @click="handleRegisterClose">
          <img src="~@/assets/images/balance/user/close.png"/>
        </div>
        
      </div>
      
      
    </div>
    <div class="register-tip" v-show="tipImgShow">
      <div class="register-bg" >
        <div class="tip-img-all">
          <img class="all-img-show" @click="handleImgShowData" src="~@/assets/images/balance/tips.png">
          <img  class="all-img-show1" src="~@/assets/images/balance/tips1.png"/>
          <img  class="all-img-show2" src="~@/assets/images/balance/tips2.png"/>
        </div>
        <div class="all-img-close" style="-webkit-app-region: no-drag;" @click="handleTipImgClose">
          <img src="~@/assets/images/balance/user/close.png"/>
        </div>
        
      </div>
      
      
    </div>
  </div>
</template>
<script>
import TIM from 'tim-js-sdk'
import TIMUploadPlugin from 'tim-upload-plugin';
import { guessingList,userinfo } from "@/api/wallet";
import { getUserInfo } from "@/api/userInfo";
import Register from "./first/common/register";
import {
  encryptByDESModeCBCUtf8to64,
  decryptByDESModeCBC
} from "@/common/utils/des";
import {
  callNetwork,signInStatus
} from "@/api/wallet";
import {
  importUserId,updateLgUserInfoToIm,getAppData
} from "@/api/balance/message";

import { getMemberInfo } from "@/api/member";
import  Messaging  from "@/components/messaging";
export default {
  name: "Shop",
  data() {
    return{
      lgUserId: "",
      tab1:[
        {
          // id: 699,
          // name: '',
          // path: '',
          // imgUrl: require('@/assets/images/balance/99.png')

        },
     
        {
          id: 1,
          name: '',
          path: '',
          imgUrl: require('@/assets/images/balance/1.png')
        },
        {
          id: 2,
          name: '',
          path: '',
          imgUrl: require('@/assets/images/balance/2.png')
        },
        {
          id: 3,
          name: '',
          path: '',
          imgUrl: require('@/assets/images/balance/3.png')
        },
        {
          id: 4,
          name: '',
          path: '',
          imgUrl: require('@/assets/images/balance/4.png')
        },
        {
          id: 5,
          name: '',
          path: '/userInfo',
          imgUrl: require('@/assets/images/balance/5.png')
        },
      ],
      tab2:[
        {
          
          id: 6,
          name: '首页',
          path: '/first',
          
        },
        { 
          id: 7,
          path: '/game',
          name: '游戏陪玩'
        },
        {
          id: 8,
          path: '/guessing',
          name: '网费竞猜'
        },
        {
          id: 9,
          path: '/square',
          name: '广场'
        },
        {
          id: 12,
          path: '/shoping',
          name: '霖商城'
        },
        {
          id: 10,
          path: '/chatRoom',
          name: '聊天室'
        },
        {
          id: 13,
          path: '/match',
          name: '电竞馆赛事'
        },
        {
          id: 11,
          path: '/wallet',
          name: '消费记录'
        },
      ],
      tabIdx: 6,
      tabCallShow: false,
      // 呼叫网管
      messagePhone: false,
      messageForm: {
        show: false,
        status: false,
        type: 1,
        message: '',
      },
      oldTime: '',
      messageTipShow: true,
      messageingShow: false,

      // IM聊天
      tim: null,
      appSDKApp: {},
      userList: [],
      messageList: [],
      goupMessage: [],
      guessingShow: false,
      guessingtype: '',
      registerShow: false,
      tipImgShow: true
      
    }
  },
  components: { Messaging,Register },
  computed:{
  },
  methods: {
    handleImgShowData() {
      this.tipImgShow = false
      this.registerShow = true
      this.$refs.registerChild.handleListData();
    },
    handleTipImgClose() {
      this.tipImgShow = false
      sessionStorage.registerShowHide = true
    },
    // 签到-打开
    handleRegisterOpen() {
      this.registerShow = true
      this.$refs.registerChild.handleListData();

    },
    // 签到-关闭
    handleRegisterClose() {
      this.registerShow = false
      sessionStorage.registerShowHide = true
    },
    handleGuessingData(type) {
      this.$store.state.bntType = 8
      if(type == 1) {
        this.guessingtype = 1
        this.$router.push('/guessing')
      }else{
        this.guessingtype = 2
        this.$router.push('/answer')
      }
    },
    // 关闭聊天弹窗
    handleMessageingClose() {
      this.messageingShow = false
    },
    // 关闭弹窗
    handleHeadCloseData() {
      this.messagePhone = false
    },
    initData() {
      var query = this.$route.query;
      if (query.secret) {
        sessionStorage.setItem("secret", decodeURIComponent(query.secret));
        this.getInfo(query.secret);
        return false;
      }
      this.isLogin = false;
      this.$message.error("非法登录");
    },
    getInfo(data) {
      getMemberInfo({
        data: data
      }).then(res => {
        if (res.code == 200) {
          this.info = JSON.parse(decryptByDESModeCBC(res.msg));
          this.isLogin = true;
          return false;
        }
        this.isLogin = false;
      });
    },
    closeWin() {
      if (window.Qbian) {
        var ipc = window.Qbian.ipc;
        ipc.send("window-balance-min"); 
        this.$nextTick(() => {
          
          try {
            this.$refs['Childmain'].handleCloseAydio()
            throw new Error("手动抛出异常!");
          } catch (e) {
          }
        })
      } else {
        console.log("窗体JS还未加载完毕");
      }
    },
    /**
     * 同步资料
     */
    handleUpdateLgUserInfoToIm(lgUserId) {
      updateLgUserInfoToIm({lgUserId: lgUserId}).then(res=>{
        if(res.code == 200) {
        }
      })

    },
    handleImportUserId(lgUserId) {
      
      importUserId({
        lgUserId: lgUserId
      }).then(res => {
        if (res.code == 200) {

        }
      });
    },
    handleTabData(item,idx) {
      this.handleGetUserInfo()
      this.signInStatus()
      // this.tipImgShow = true
      // console.log( encryptByDESModeCBCUtf8to64(JSON.stringify({shopId: 7429})))
      // console.log( decryptByDESModeCBC('cXZTIDfSbiPE7raHYETgPw=='))
      
      if(item.id == 3) {
        if(window.Qbian){
          var ipc = window.Qbian.ipc;
          ipc.send('window-shop-open');
        }
      } else if (item.id == 1) {
        if(window.Qbian){
          var ipc = window.Qbian.ipc;
          ipc.send('window-comment',window.location.protocol + '//'+window.location.host+'/#/comment');
        }

      } else if (item.id == 2) {

      } else if (item.id == 4) {
        // this.$store.state.bntType = item.id
        // this.messageingShow = true
        // this.$refs.messaging.login()
        this.$store.state.gameType = true
        this.handleOpenIm()
      } else if (item.id == 10) {
        if(item.id == this.$store.state.bntType) {
          return
        }
        this.$router.push(item.path)
        this.$store.state.bntType = item.id
        this.tabIdx = this.$store.state.bntType
        this.$store.state.gameType = false
        if(!sessionStorage.IMShow) {
          this.handleAppData()

        }
      } else if (item.id == 13) {
        if(item.id == this.$store.state.bntType) {
          return
        }
        this.$router.push(item.path)
        this.$store.state.bntType = item.id
        this.tabIdx = this.$store.state.bntType
        this.$store.state.gameType = false
        if(!sessionStorage.IMShow) {
          this.handleAppData()

        }
      } else if (item.id ==  699 ) {
        this.handleRegisterOpen()
      } else if (item.id == 8) {
        this.guessingShow = !this.guessingShow
      } else {
      
        if(item.id == this.$store.state.bntType) {
          return
        }
        this.$router.push(item.path)
        this.$store.state.bntType = item.id
        this.tabIdx = this.$store.state.bntType
      }
      if(item.id != 8) {
        this.guessingtype = ''
        this.guessingShow = false
      }
    },
    onReceiveMessage({data:arr}) {
      let _this = this
      _this.userList  = _this.$store.state.userList
      let obj = {};
      let list = []
      let arrs = []
      let listObj = {};
      for(let i in arr) {
        if(arr[i].conversationType == "GROUP") {
          if(arr[i].payload.operationType == 1){
            let obj = {
              avatar: arr[i].avatar,
              nick: arr[i].nick,
              userID: arr[i].payload.userIDList[0]
            }
            _this.userList.push(obj);
            let listObj = {};
            let peon = _this.userList.reduce((cur,next) => {
            listObj[next.userID] ? "" : listObj[next.userID] = true && cur.push(next);
            return cur;
            },[])
            _this.userList  = peon
            if(_this.$store.state.bntType == 10) {
              if(!sessionStorage.IMShow) {
                _this.$refs['Childmain'].hanleGroupMemberList()
              }else{
                 _this.$store.state.userList = _this.userList
                _this.$refs['Childmain'].handleGroupProfile(true)
              }
            }
            // _this.handleGroupProfile(true)
          }else if(arr[i].payload.operationType == 3) {
            for(let key in _this.userList) {
              if(arr[i].payload.userIDList[0]  == _this.userList[key].userID) {
                
                _this.userList.splice(key,1)
              }
            }
            _this.$store.state.userList = _this.userList
            // _this.groupObj.memberCount = arr[i].payload.memberCount
          }else{      
            _this.goupMessage =  _this.$store.state.goupMessage
            let srtTime = arr[i].time * 1000
            let time = _this.$utils.dateFormat( srtTime,'yyyy-MM-dd hh:mm:ss')
            
            obj.time = _this.$utils.transDate(time)  
            obj.nickName = arr[i].nick
            obj.headImg = arr[i].avatar
            obj.userID = arr[i].from
            obj.selectd = arr[i].selectd
            obj.itemObj = arr[i]
            obj.isRevoked = arr[i].isRevoked
            obj.isRevokedTime = arr[i].time * 1000+ (60*1000 *2),
            obj.isDeleted = arr[i].isDeleted
            if(obj.userID == sessionStorage.lgUserId) {
              obj.myStatus = true
            }else{
              obj.myStatus = false
            }
            if(arr[i].payload.text) { 
              obj.text =  arr[i].payload.text
            }else{
              obj.img=arr[i].payload.imageInfoArray[2].imageUrl

            }
            _this.goupMessage.push(obj)
            _this.$store.state.goupMessage = _this.goupMessage
            if(_this.$store.state.bntType == 10) {
              _this.$refs['Childmain'].handleMessageData()
            }

          }
          // _this.$forceUpdate()
        }else if(arr[i].conversationType === "C2C"){
          if(arr[i].from == "administrator" ) {
            if(_this.$store.state.bntType == 13)  {
              _this.$refs['Childmain'].handleMatchMessage(arr[i])

            }

          }else{
            _this.$refs.messaging.handleListMessage(arr[i])
          }
        }
        else{

        }
        
      }
      _this.$forceUpdate();
    },
    /**
     * 获取签名
     */
    handleAppData(item) {
      let _this = this
      getAppData({lgUserId: sessionStorage.lgUserId}).then(res=>{
        if(res.code == 200) {
          _this.appSDKApp = JSON.parse(decryptByDESModeCBC(res.data.data));
          _this.createMessage()
        }
      })
    },
    /**
     * 创建IM
     */
    createMessage() {
      var options = {
        SDKAppID: Number(this.appSDKApp.appId)  // 接入时需要将 0 替换为您的云通信应用的 SDKAppID
      };
      this.tim = TIM.create(options);
      this.$store.state.tim = this.tim
      // this.tim.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
      this.tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用
      // 注册腾讯云即时通信 IM 上传插件，即时通信 IM SDK 发送图片、语音、视频、文件等消息需要使用上传插件，将文件上传到腾讯云对象存储
      this.tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});
      // this.roomInitListener()
      if(this.$store.state.gameType ) {
        this.$refs.messaging.handleMessageingOpen()
      }
      if(!sessionStorage.IMShow) {
        this.roomInitListener()
        if(this.$store.state.bntType == 10 || this.$store.state.bntType == 13) {
          this.$refs['Childmain'].login(this.appSDKApp)
        }
      }else{
      }
    },
    /**
     * 监听事件
     */
    roomInitListener() {
      let _this = this
        // 监听事件，例如：
      this.tim.on(TIM.EVENT.SDK_READY, function(event) {
        if(_this.$store.state.bntType == 10) {
          _this.$refs['Childmain'].handleAddGroupMember()
          _this.$refs['Childmain'].hanleGroupMemberList(false)
          _this.$refs['Childmain'].handleGroupProfile()
        }else if(_this.$store.state.bntType == 13){

          
        }else if(_this.$store.state.bntType == 4){
          _this.$refs.messaging.handlegetConversationList(true)
        }
      // 收到离线消息和会话列表同步完毕通知，接入侧可以调用 sendMessage 等需要鉴权的接口
      });
      this.tim.on(TIM.EVENT.MESSAGE_RECEIVED, _this.onReceiveMessage)
      this.tim.on(TIM.EVENT.MESSAGE_REVOKED, function(event) {
        let arr = event.data
        for(let i in _this.messageList) {
          for(let key in arr) {
            if(arr[i].conversationID == 'C2Cadministrator'){

            }else{
              if(_this.messageList[i].itemObj.ID == arr[key].ID) {
                _this.messageList[i].isRevoked = arr[key].isRevoked
              }

            }
          }
        }
        _this.$store.state.messageList = _this.messageList
        _this.$forceUpdate();
      // 收到消息被撤回的通知
      // event.name - TIM.EVENT.MESSAGE_REVOKED
      // event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isRevoked 属性值为 true
      });
      this.tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, function(event) {
      // SDK 收到对端已读消息的通知，即已读回执。使用前需要将 SDK 版本升级至 v2.7.0 或以上。仅支持单聊会话。
      // event.name - TIM.EVENT.MESSAGE_READ_BY_PEER
      // event.data - event.data - 存储 Message 对象的数组 - [Message] - 每个 Message 对象的 isPeerRead 属性值为 true
      });
      this.tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, function(event) {  
        let arr = event.data
        for(let i in arr) {
          if(arr[i].type == "C2C") {
            if(arr[i].conversationID == 'C2Cadministrator'){

            }else{
              if(arr[i].unreadCount>0) {
                _this.$store.state.messageShow = true

              }

            }
          }
        }
        // 收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面
        // event.name - TIM.EVENT.CONVERSATION_LIST_UPDATED
        // event.data - 存储 Conversation 对象的数组 - [Conversation]
        // _this.$forceUpdate()
     
      });
      this.tim.on(TIM.EVENT.GROUP_LIST_UPDATED, function(event) {
      // 收到群组列表更新通知，可通过遍历 event.data 获取群组列表数据并渲染到页面
      // event.name - TIM.EVENT.GROUP_LIST_UPDATED
      // event.data - 存储 Group 对象的数组 - [Group]
      });
      this.tim.on(TIM.EVENT.PROFILE_UPDATED, function(event) {
      // 收到自己或好友的资料变更通知
      // event.name - TIM.EVENT.PROFILE_UPDATED
      // event.data - 存储 Profile 对象的数组 - [Profile]
      });
      this.tim.on(TIM.EVENT.BLACKLIST_UPDATED, function(event) {
      // 收到黑名单列表更新通知
      // event.name - TIM.EVENT.BLACKLIST_UPDATED
      // event.data - 存储 userID 的数组 - [userID]
      });
      this.tim.on(TIM.EVENT.ERROR, function(event) {
      // 收到 SDK 发生错误通知，可以获取错误码和错误信息
      // event.name - TIM.EVENT.ERROR
      // event.data.code - 错误码
      // event.data.message - 错误信息
      });
      this.tim.on(TIM.EVENT.SDK_NOT_READY, function(event) {
      // 收到 SDK 进入 not ready 状态通知，此时 SDK 无法正常工作
      // event.name - TIM.EVENT.SDK_NOT_READY
        // _this.handleFriendList()
      });
      this.tim.on(TIM.EVENT.KICKED_OUT, function(event) {
      // 收到被踢下线通知
      // event.name - TIM.EVENT.KICKED_OUT
      // event.data.type - 被踢下线的原因，例如:
      //    - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
      //    - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
      //    - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢 （v2.4.0起支持）。 
      });
      this.tim.on(TIM.EVENT.NET_STATE_CHANGE, function(event) { 
      //  网络状态发生改变（v2.5.0 起支持）。 
      // event.name - TIM.EVENT.NET_STATE_CHANGE 
      // event.data.state 当前网络状态，枚举值及说明如下： 
      //     \- TIM.TYPES.NET_STATE_CONNECTED - 已接入网络 
      //     \- TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中” 
      //    \- TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息  
      });
    

    },
    handleOpenIm(data) {
      if(sessionStorage.lgUserId) {
        this.handleAppData()
      }
      if(data) {
        this.$store.state.listObj = data
      }
    },
     /**
      * IM 登录 
      */
    // 呼叫网管弹窗按钮
    handleCallData(status) {
        this.messageForm.message = ''
      if(status == 1) {
        this.messageForm.type = 1
        this.handleMessageAdd(status)

      } else{
        this.messageForm.type = 2
        this.messagePhone = true
      }
    },
    // 呼叫网管提交
    handleMessageAdd(status) {
      var timestamp=new Date().getTime()
      this.oldTime = sessionStorage.oldTime
      if(this.oldTime) {
        /**
         * 判断是否有30s超过了可以继续呼叫
         */
        let times=  Math.abs(timestamp - this.oldTime ) >= (0.5 * 60 * 1000)
        if(!times) {
          this.$message.error("呼叫太频繁了~");
          return
        }
        
      }
      if(status == 2) {
        if(!this.messageForm.message) {
          this.$message.error("请输入你的留言~");
          return 
        }
      }
      let params= {
        type: this.messageForm.type,
        message: this.messageForm.message,
      }
      callNetwork(params).then(res => {
        if(res.code==200) {
          sessionStorage.oldTime = res.data.time
          this.$message({
              message: '已呼叫网管,请稍等~',
              type: 'success',
          });
          this.messageForm.show = true
          this.messagePhone = false
        }
      })

    },
    handleMessagePhoneClose() {
      this.messagePhone = false
    },
    handleUserinfo() {
      userinfo().then(res => {
        if (res.code == 200) {
          let info = JSON.parse(decryptByDESModeCBC(res.msg));
          this.lgUserId = info.memberId
          sessionStorage.lgUserId = info.memberId
          this.handleGetUserInfo()
        }
      }).catch(res=>{
      });

    },
    /**
     * 获取个人信息
     */
    handleGetUserInfo() {
      getUserInfo({ lgUserId: sessionStorage.lgUserId }).then(res => {
        if (res.code == 200) {
          this.handleImportUserId(res.data.lgUserId)
          this.userInfo = res.data;
          this.certification = res.data.certification;
          this.lgUserId =  res.data.id
          sessionStorage.certification = res.data.certification;
          sessionStorage.playWith = res.data.playWith
          sessionStorage.phone = res.data.phone;
          sessionStorage.headImg =  res.data.headImg;
          sessionStorage.nickName =  res.data.nickName;
          sessionStorage.lgUserId =  res.data.lgUserId;
          this.handleUpdateLgUserInfoToIm(res.data.lgUserId)
        }
      });
    },
    signInStatus() {
      signInStatus().then(res => {
        if (res.code == 200) {
          this.isShow = res.data.isShow
          if(res.data.isShow == 1) {
            this.tab1[0] = {
              id: 699,
              name: '',
              path: '',
              imgUrl: require('@/assets/images/balance/99.png')
            }
            if(sessionStorage.registerShowHide) {
              this.tipImgShow = false
            }else{
              this.tipImgShow = true
            }
          }else{
            this.tab1[0] = {
            }

          }
          this.$forceUpdate();
        }
      }).catch(res=>{
      });
    },
  },
  created() {},
  mounted() {
    this.lgUserId = sessionStorage.lgUserId
    this.handleUserinfo()
    this.initData(); 
    this.signInStatus()
  },
  destroyed() {
    
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
<style lang="less" scoped>
  .messageing-tip-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 22;

  }
  .messageing-tip{
    position: fixed;
    width: 835px;
    height: 588px;
    top: 55%;
    left: 50%;
    background: #fff;
    transform: translate(-50%,-50%);
    z-index: 33;
    .tip-close{   
      position: absolute;
      width: 40px;
      height: 40px;
      top: -26px;
      right: -20px;
      z-index: 44;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
      }
    }

  }
</style>
