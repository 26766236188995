<template>
  <div class="job">
    <div v-if="status == 0">
      <div class="job-tab" style="-webkit-app-region: no-drag;" >
        <div class="tab-title">岗位招聘</div>
        <ul class="tab-ul ">
          <li class="ul-status" :class="{'ui-show-bg': idx == navIdx}" v-for="(item,idx) in navList" :key="idx" @click="handleNavData(item,idx)">
            <div class="status-show" v-if="idx == navIdx"></div>
            <div>{{item.name}}</div>
          </li>
        </ul>
      </div>
      <div class="job-list" v-if="listData.length > 0">
        <div class="list-item" v-for="(item,idx) in listData" :key="idx" @click="handleJobListData(item,idx)">
          <div class="item-title">
            <div class="title-left">{{item.positionName}}</div>
            <div class="title-right">
              <img src="~@/assets/images/balance/first/left.png">
            </div>
          </div>
          <div class="item-flex item-ads">
            <div class="left-adds">工作地点：{{item.workPlace}}</div>
            <div class="left-num">招聘人数：{{item.number}}人</div>
          </div>
          <div class=" item-money">
            <div class="money-left">{{item.minCompensation}}~{{item.maxCompensation}}</div>
            <div class="money-right">{{$utils.dateFormat(item.updateTime, 'yyyy-MM-dd')}}</div>
          </div>
        </div>
      </div>
      <div class="job-list" v-else>
        <div class="lists-no">
          <div class="no-data">
            <img src="~@/assets/images/balance/no-data.gif">
          </div>
        </div>
      </div>
    </div>
    <div class="job-details" v-else-if="status ==1">
      <div class="details-title" style="-webkit-app-region: no-drag;">
        <img style="-webkit-app-region: no-drag;" @click="handleBack" src="~@/assets/images/balance/game/back.png"/>
        <div class="title-h3">岗位详情</div>
      </div>
      <div class="details-all">
        <div class="details-position">
          <div class="position-ch">{{detailsObj.positionName}}</div>
        </div>
        <div class="details-itme">
          <div class="item-duty">
            <div class="item-title">岗位职责</div>
            <div class="item-ul">
              <div v-html="detailsObj.positionDescription.replace(/(\r\n|\n|\r)/gm,'<br />')"></div>
            </div>
          </div>
          <div class="item-claim">
            <div class="item-title">岗位要求</div>
            <div class="item-ul">
              <div v-html="detailsObj.positionAskFor.replace(/(\r\n|\n|\r)/gm,'<br />')"></div>
            </div>
          </div>
          <div class="item-min">
            <div class="min-left">
              <div>   
                <div class="left-margin">
                  欢迎搜索并关注霖感会员小程序，建议
                  </div>    
                  电话联系进行岗位咨询或者扫码微信联系~
              </div>
              <div>
                联系电话:{{detailsObj.linkManPhone}}
              </div>
            </div>
            <div class="min-right">
              <img :src="detailsObj.bringInManagerImg">
            </div>
          </div>
        </div>
      </div>
      <div class="details-add" @click="handleApplyData">申请岗位</div>
    </div>
    <div class="job-details" v-else-if="status ==2">
      <div class="details-title" style="-webkit-app-region: no-drag;">
        <img style="-webkit-app-region: no-drag;" @click="handleBack" src="~@/assets/images/balance/game/back.png"/>
        <div class="title-h3">申请岗位</div>
      </div>
      <div class="job-form" style="-webkit-app-region: no-drag;">
        <div class="form-item">
          <div class="form-label">姓<span v-html="'\&#12288;\&#12288;'"></span>名：</div>
          <div class="form-input">
            <input v-model="addForm.name" placeholder="请输入姓名"/>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">性<span v-html="'\&#12288;\&#12288;'"></span>别：</div>
          <div class="form-input">
            <el-select v-model="addForm.sex" size="small" placeholder="请选择岗位">
              <el-option
                v-for="item in sexList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">手机号码：</div>
          <div class="form-input">
            <input v-model="addForm.phone" placeholder="请输入手机号码"/>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">应聘岗位：</div>
          <div class="form-input">
            <div>{{detailsObj.positionName}}</div>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">教育经历：</div>
          <div class="form-textarea">
            <textarea rows="4" v-model="addForm.educationExp" placeholder="请输入教育经历"></textarea>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">工作经历：</div>
          <div class="form-textarea">
            <textarea v-model="addForm.workExp" rows="4" placeholder="请输入工作经历"></textarea>
          </div>
        </div>
        <div class="form-add" @click="handleAddData">提交申请</div>
      </div>
    </div>
  </div>
</template>
<script>
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC,getAstro }  from '@/common/utils/des'
import { bringInList,bringInInfo,applyFor, } from "@/api/balance/first";
export default {
  name: "job",
  data() {
    return {
      status: 0,  // 0的列表 1是详情 2是申请岗位
      navList:[
        {
          id: '',
          name: '全部'
        },
        {
          id: 1,
          name: '公司'
        },
        {
          id: 2,
          name: '门店'
        },

      ],
      listData:[],
      navIdx: 0,
      parentId: '',
      pageSize: 999,
      pageNum: 1,
      detailsObj: {},
      addForm: {
        lgUserId: '',
        name: '',
        sex: 0,
        phone: '',
        bringInId: '',
        educationExp: '',
        workExp: '',
      },
      sexList: [
        {
          id: 0,
          name: '男'
        },
        {
          id: 1,
          name: '女'
        },
      ],
      detailsObj:{
        positionDescription: '',
        positionAskFor: '',
      },
      loading: false,
      totalNum: 0
    };
  },
  components: {},
  computed: {
  },
  methods: {
  
    loadMore() {
      this.handleFirst();
    },
    handleFirst() {
      let params = {
        parentId: this.parentId,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      bringInList(params).then(res => {
        if (res.code == 200) {
          this.listData = res.rows
          this.totalNum = res.total
          this.loading = this.active < res.total;
        }
      })

    },
    // 申请岗位
    handleApplyData() {
      this.status = 2
      this.handleInitialization()
      
    },
    // 返回岗位列表
    handleBack() {
      this.status = 0
    },
    // 跳转详情
    handleJobListData(item,idx) {
      this.status = 1
      bringInInfo({id: item.id}).then(res => {
        if (res.code == 200) {
          this.detailsObj = res.data
        }
      })
    },
    // 菜单按钮
    handleNavData(item,idx) {
      this.navIdx = idx
      this.parentId = item.id
      this.handleFirst()
    },
    // 提交申请
    handleAddData() {
      if(!this.addForm.name) {
        this.$message.error('姓名不能为空!');
        return
      }
      if(this.$utils.phoneVerif(this.addForm.phone)) {
        this.$message.error('手机号码格式不正确~');
        return
      }
      if(!this.addForm.educationExp) {
        this.$message.error('教育经历不能为空!');
        return
      }
      this.addForm.lgUserId = sessionStorage.lgUserId
      this.addForm.bringInId = this.detailsObj.id
      applyFor(this.addForm).then(res => {
        if (res.code == 200) {
          
          this.$message({
              message: res.msg || '申请成功!',
              type: 'success',
          });
          this.handleInitialization()
          this.handleFirst()
        }else{
          
          this.$message.error(res.msg || '申请失败!');
        }
        this.status = 0
      })
    },
    handleInitialization() {
      this.addForm = {
        lgUserId: sessionStorage.lgUserId,
        name: '',
        sex: 0,
        phone: '',
        bringInId: '',
        educationExp: '',
        workExp: '',
      }
    },

  },
  created() {},
  mounted() {
    // this.handleFirst()
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>

@import "./job.less";
</style>
