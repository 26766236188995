import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/index.vue')
  },
  {
    path: '/setShop',
    name: 'SetShop',
    component: () => import('../views/setShop/index.vue')
  },
  {
    path: '/homePage',
    name: 'homePage',
    component: () => import('../views/homePage/index.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/shop/index.vue')
  },
  {
    path: '/check',
    name: 'Check',
    component: () => import('../views/checkOut/index.vue')
  },
  {
    path: '/comment',
    name: 'Comment',
    component: () => import('../views/comment/index.vue')
  },
  {
    path: '/balance',
    name: 'Balance',
    redirect: '/first',
    component: () => import('../views/balance/index.vue'),
    children: [
      {
        path: '/first', // 首页
        name: 'First',
        component: () => import('../views/balance/first/index.vue')
      },
      {
        path: '/userinfo', // 个人中心
        name: 'UserInfo',
        component: () => import('../views/balance/userInfo/index.vue')
      },
      {
        path: '/guessing', // 赛事竞猜
        name: 'Guessing',
        component: () => import('../views/balance/guessing/index.vue')
      },
      {
        path: '/answer', //答题活动
        name: 'Answer',
        component: () => import('../views/balance/answer/index.vue')
      },
      {
        path: '/wallet', // 消费记录
        name: 'Wallet',
        component: () => import('../views/balance/wallet/index.vue')
      },
      {
        path: '/square', // 炸蛋广场
        name: 'Square',
        component: () => import('../views/balance/square/index.vue')
      },
      {
        path: '/game', // 游戏陪玩
        name: 'Game',
        component: () => import('../views/balance/game/index.vue')
      },
      {
        path: '/details', // 游戏陪玩-详情页面
        name: 'Details',
        component: () => import('../views/balance/game/details.vue')
      },
      {
        path: '/shoping', // 霖感商城
        name: 'Shoping',
        component: () => import('../views/balance/shoping/index.vue')
      },
      {
        path: '/shopDetails', // 霖感商城-详情页面
        name: 'ShopDetails',
        component: () => import('../views/balance/shoping/details.vue')
      },
      {
        path: '/chatRoom', // 聊天室
        name: 'ChatRoom',
        component: () => import('../views/balance/chatRoom/index.vue')
      },
      {
        path: '/match', // 电竞馆赛事
        name: 'Match',
        component: () => import('../views/balance/match/index.vue')
      },
      {
        path: '/matchLook', // 电竞馆赛事-战绩查询
        name: 'MatchLook',
        component: () => import('../views/balance/match/look.vue')
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
