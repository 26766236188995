<template>
  <div class="first">
    <div class="first-content">
      <div class="content-left">
        <div class="left-user">
          <div class="user-info">
            <div class="info-img">
              <img class="img-horn" src="~@/assets/images/balance/first/horn.png" />
              <img class="img-log" src="~@/assets/images/balance/first/log.png" />

            </div>
            <div class="info-ul">
              <div>门店名称：{{infoData.shopName}}</div>
              <div>机器号：{{infoData.deviceNumber}}</div>
              <div v-html="rate.replace(/(\r\n|\n|\r)/gm,'<br />')"></div>
            </div>
            <!-- <div>{{rate}}</div> -->
            <!-- <div>会员级别：普通会员</div>
            <div>账户余额：186.56元</div>
            <div>上网区域：单人包10元/小时</div>
            <div>上网时长：01：56</div>
            <div>本次消费：20.0元</div>
            <div>机器号：003</div> -->
          </div>
        </div>
        <div class="left-game" @click="handleImgOpen(advertising[0])">
          <img v-if="advertising.length>0" :src="advertising[0].imgUrl" class="game-img">
          <!-- <img src="~@/assets/images/balance/first/gameIco.png" />
          <div class="game-right">
            <div>游戏陪玩</div>
            <div>各种游戏等你来</div>
          </div> -->
        </div>
      </div>
      <div class="content-center">
        <div class="center-banner">
          <el-carousel height="100%" class="banner-carousel" arrow="never">
            <el-carousel-item v-for="(item,idx) in bannerList" :key="idx">
              <div class="carousel-all"  @click="handleImgOpen(item)">
                <img class="banner-img" :src="item.imgUrl"/>
                <!-- <div class="all-flex">
                  <div class="flex-left"></div>
                  <div class="flex-right">某某某轮播图</div>
                </div> -->

              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="center-flex">
          <div class="flex-left"  @click="handleImgOpen(advertising[1])">
            
            <img  v-if="advertising.length>0" :src="advertising[1].imgUrl +'?imageView2/2/h/138'" class="game-img">
            <!-- <img
              class="left-hot"
              src="~@/assets/images/balance/first/hot.png"
            />
            <div class="left-bg">榜单</div>
            <div class="left-text">
              <div>热门比赛</div>
              <div>英雄联盟</div>
            </div> -->
          </div>
          <div class="flex-right"  @click="handleImgOpen(advertising[2])">
            <img  v-if="advertising.length>0" :src="advertising[2].imgUrl +'?imageView2/2/h/138'" class="game-img">
            <!-- <img
              class="right-heart"
              src="~@/assets/images/balance/first/heart.png"
            />
            <div class="right-bg">榜单</div>
            <div class="right-text">
              <div>热门动态话题</div>
              <div>有奖竞猜，你们中了吗？</div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="content-right">
        <!-- <div class="right-walking">
          <div class="walking-th">
            <div class="guessing-no">会员卡号</div>
            <div class="guessing-flex">竞猜门店</div>
            <div class="guessing-monty">中奖网费</div>
          </div>
          <div class="walking-list">
            <vue-seamless-scroll :data="guessinglist" class="seamless-warp"  :class-option="classOption">
              <div class="guessing-td" v-for="(item,index) in guessinglist" :key="index">
                <div class="guessing-no">{{item.memberNumber}}</div>
                <div class="guessing-flex">{{item.shopName}}</div>
                <div class="guessing-monty">{{item.winningMoney || 0}}</div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div> -->
        <div class="right-walking">
          <div class="walking-th">
            <div class="guessing-no">排名</div>
            <div class="guessing-no">姓名</div>
            <div class="guessing-flex">手机号</div>
            <div class="guessing-monty">充值金额</div>
          </div>
          <div class="walking-list">
            <div
              class="guessing-td"
              :class="[index==0?'guessing-td_show1':index==1?'guessing-td_show2':index==2?'guessing-td_show3':'']"
              v-for="(item, index) in guessinglist"
              :key="index"
            >
              <div class="guessing-no">
                <img class="no-icon" v-if="index==0" src="~@/assets/images/balance/first/icon1.png/"/>
                <img class="no-icon" v-if="index==1"  src="~@/assets/images/balance/first/icon2.png/"/>
                <img class="no-icon" v-if="index==2"  src="~@/assets/images/balance/first/icon3.png/"/>
                  {{ index+1 }}
              </div>
              <div class="guessing-no">{{ item.memberName }}</div>
              <div class="guessing-flex">{{ item.memberNumber }}</div>
              <div class="guessing-monty">
                ¥{{ item.accruingMoney || 0 }}
              </div>
            </div>
          </div>
          <div class="walking-my">
            <div v-if="myRankingShow == 'true'">
             我的当前充值金额是￥{{myRanking.money|| 0}}，排名：{{myRanking.myRanking || 0}}

            </div>
            <div v-else class="my-look" @click="handleMyRanking">查看我的排名</div>
          </div>
        </div>
        <div class="right-wechat">
          <div class="wechat-flex" @click="handleImgOpen(advertising[3])">
            <!-- <div class="flex-left">
              <div class="left-title">微信小程序</div>
              <div class="left-text">扫码体验</div>
              <div class="left-status"> 查充网费 / 远程下机 预定座位</div>
            </div>
            <div class="wechat-QR"></div> -->
            
            <img  v-if="advertising.length>0" :src="advertising[3].imgUrl" class="game-img">
          </div>

        </div>
      </div>
    </div>
    <div class="first-advertising">
      <img  @click="handleImgOpen(waterList[0])" v-if="waterList.length>0" :src="waterList[0].imgUrl" class="game-img">
    </div>
    <!-- 弹窗 -->
    <div class="tip-bg" v-show="tipShowHide"></div>
    <div class="tip" v-show="tipShowHide" :style="{width: tipWidth,height: tipHeight}">
      <div class="tip-close" style="-webkit-app-region: no-drag;" @click="handlePhoneCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-cnter">
        <!-- <iframe name="tipIframe"  frameborder="0" style="width:100%;height:100%;"  :src="iframeUrl"></iframe> -->
        <!-- <iframe :src="iframeUrl" width="100%" height="100%" frameborder="0" scrolling="auto"></iframe> -->
        <!-- <webview id="geekori" :src="iframeUrl" style="width:500px; height:500px" nodeintegration="true"></webview> -->
        <webview id="geekori" :src="iframeUrl" ></webview>
      </div>
      
    </div>
    <!-- 招聘弹窗 -->
    <div class="tip-bg" v-show="jobShow"></div>
    <div class="tip" v-show="jobShow">
      <div class="tip-close" style="-webkit-app-region: no-drag;" @click="handleJobCloseData">
        <img src="~@/assets/images/balance/user/close.png"/>
      </div>
      <div class="tip-job">
        <Job ref="jobChild"></Job>
      </div>
      
    </div>
    

  </div>
</template>

<script>
// import { shell } from 'electron'
const {shell} = window.require('electron');
// const { app, BrowserWindow, shell, ipcMain } = require('electron');
import { slideshow,shopInfo,rechargeRanking,myRechargeRanking } from "@/api/balance/first";
import { guessingList,userinfo,signInStatus } from "@/api/wallet";
import {
  importUserId
} from "@/api/balance/message";
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC,getAstro }  from '@/common/utils/des'

import Job from "./common/job";
import Register from "./common/register";
import PopupTip from "@/components/popupTip";
export default {
  name: "First",
  data() {
    return {
      iframeUrl: '',
      tipWidth: '600px',
      tipHeight: '600px',
      tipShowHide: false,
      bannerList: [
        {
          srcImg: require('@/assets/images/balance/game/1.png')
        },
        {
          srcImg: require('@/assets/images/balance/game/2.png')
        },
        {
          srcImg: require('@/assets/images/balance/game/3.png')
        },
        {
          srcImg: require('@/assets/images/balance/game/4.png')
        },
      ],
      guessinglist: [],
      advertising: [],
      waterList:[],
      infoData: {},
      rate: '',
      myRanking: {},
      myRankingShow: false,
      jobShow: false,
      title: '我的弹窗',
      registerShow: false

    };
  },
  components: { Job,PopupTip },
  computed: {
    // classOption() {
    //   return {
    //     step: 0.5, // 数值越大速度滚动越快
    //     limitMoveNum: this.guessinglist.length, // 开始无缝滚动的数据量 this.dataList.length
    //     hoverStop: true, // 是否开启鼠标悬停stop
    //     direction: 1, // 0向下 1向上 2向左 3向右
    //     openWatch: true, // 开启数据实时监控刷新dom
    //     singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //     singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //     waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
    //     switchSingleStep: 200
    //   };
    // }
  },
  methods: {
    // 关闭职位弹窗
    handleJobCloseData() {
      this.jobShow = false
    },
    // 查看我的排名
    handleMyRanking() {
      this.handleMyRechargeRanking()
      sessionStorage.myRanking = true
      this.myRankingShow = sessionStorage.myRanking 
    },
    handlePhoneCloseData() {
      this.tipShowHide = false

    },
    // 点击图片
    handleImgOpen(item) {
      if(item.clickType == 1) {
        if(item.clickUrl) {
          shell.openExternal(item.clickUrl)
        }
      }else if(item.clickType == 4){ 
        if(item.clickUrl =='/shop') {
          if(window.Qbian){
            var ipc = window.Qbian.ipc;
            ipc.send('window-shop-open');
          }
          this.$store.state.bntType = 6
          return
        } else if(item.clickUrl =='/first') {
          this.$store.state.bntType = 6
          this.$router.push(item.clickUrl)
        }else if(item.clickUrl =='/userInfo') {
          this.$store.state.bntType = 5
          this.$router.push(item.clickUrl)
        }else if(item.clickUrl =='/game') {
          this.$store.state.bntType = 7
          this.$router.push(item.clickUrl)
        }else if(item.clickUrl =='/guessing') {
          this.$store.state.bntType = 8
          this.$router.push(item.clickUrl)
        }else if(item.clickUrl =='/square') {
          this.$store.state.bntType = 9
          this.$router.push(item.clickUrl)
        }else if(item.clickUrl =='/wallet') {
          this.$store.state.bntType = 11
          this.$router.push(item.clickUrl)
        }else if(item.clickUrl =='recruitment') {
          this.jobShow = true
          this.$refs.jobChild.handleFirst();
        }else if(item.clickUrl =='/register') {
          signInStatus().then(res => {
            if (res.code == 200) {
              if(res.data.isShow == 1) {
                this.$parent.handleRegisterOpen();
              }
            }
          }).catch(res=>{
          });

        }
      } else{
        this.iframeUrl = item.clickUrl
        if(item.clickUrl == "recruitment") {
          this.jobShow = true
        }else {
          let width =  1200/100
          let height =  800/100
          this.tipWidth = (width*item.openSize).toFixed()  + 'px'
          this.tipHeight = (height*item.openSize).toFixed() + 'px'
          this.tipShowHide = true
        }
      }
    },

    // 轮播图
    handleslideshow() {
      slideshow({type: 2}).then(res => {
        if (res.code == 200) {
          this.bannerList = res.data
        }
      });
      slideshow({type: 3}).then(res => {
        if (res.code == 200) {
          this.advertising = res.data
        }
      });
      slideshow({type: 5}).then(res => {
        if (res.code == 200) {
          this.waterList = res.data
        }
      });
    },
    // 走马灯竞猜
    // handleGuessingList() {
    //   guessingList().then(res => {
    //     if (res.code == 200) {
    //       this.guessinglist = res.data
    //     }  
    //   }); 
    // },
    // 充值排行
    handleGuessingList() {
      rechargeRanking().then(res => {
        if (res.code == 200) {
          this.guessinglist = res.data;
          if(sessionStorage.lgUserId) {
            this.handleMyRechargeRanking()
            sessionStorage.myRanking = true
          }else{
            sessionStorage.myRanking = false
          }
          this.myRankingShow = sessionStorage.myRanking
        }
      });
    },
    handleUserinfo() {
      let info = JSON.parse(decryptByDESModeCBC(sessionStorage.secret));
      this.infoData.deviceNumber = info.deviceNumber
      if(info.shopId == 7429) {
        this.infoData.shopName = '明珠广场店'
      }else if(info.shopId == 7496) {
        this.infoData.shopName = '新建吾悦店'
      }else if(info.shopId == 7481) {
        this.infoData.shopName = '王府井店'
      }else if(info.shopId == 7519) {
        this.infoData.shopName = '万科天空之城店'
      }else if(info.shopId == 7468) {
        this.infoData.shopName = '测试店'
      }else if(info.shopId == 7578) {
        this.infoData.shopName = '万科时代广场店'
      }else{
        this.infoData.shopName = info.shopName
      }
      sessionStorage.shopName = this.infoData.shopName
      // switch (info.shopId) {
      //   case 7429:
      //     this.infoData.shopName = '明珠广场店'
      //     sessionStorage.shopName = this.infoData.shopName
      //     break;
      //   case 7496:
      //     this.infoData.shopName = '新建吾悦店'
      //     sessionStorage.shopName = this.infoData.shopName
      //     break;
      //   case 7481:
      //     this.infoData.shopName = '王府井店'
      //     sessionStorage.shopName = this.infoData.shopName
      //     break;
      //   case 7519:
      //     this.infoData.shopName = '万科天空之城店'
      //     sessionStorage.shopName = this.infoData.shopName
      //     break;
      //   case 7468:
      //     this.infoData.shopName = '测试店'
      //     sessionStorage.shopName = this.infoData.shopName
      //     break;
      //   case 7578:
      //     this.infoData.shopName = '万科时代广场店'
      //     sessionStorage.shopName = this.infoData.shopName
      //     break;
      //   default:
      //       break;
      // }
      shopInfo().then(res => {
        if (res.code == 200) {
          if(res.data) {
            this.rate = res.data.rate.replace(/↵/g,"\n");
          }
        }
      })
      

    },
    handleMyRechargeRanking() {
      
      myRechargeRanking({lgUserId: sessionStorage.lgUserId}).then(res => {
        if (res.code == 200) {
          this.myRanking = res.data
            // this.rate = res.data.rate.replace(/↵/g,"\n");
          
        }
      })
    },
    handleImportUserId() {
      
      importUserId({
        lgUserId: sessionStorage.lgUserId
      }).then(res => {
        if (res.code == 200) {
        }
      });
    },
    
  },
  created() {},
  mounted() {
    this.$nextTick(()=>{
      this.handleUserinfo()
    });
    // this.handleImportUserId()
    this.handleGuessingList();
    this.handleslideshow()
  },
  destroyed() {}
};
</script>
<style lang="less">
@import "./index.less";
</style>
