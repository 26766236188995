import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from '@/common/utils'

// 样式引入
import '@/assets/style/index.less'
// import 'swiper/css/swiper.css'
import ElementUI from 'element-ui';
import Pagination from "@/components/Pagination";
import vueSeamlessScroll from 'vue-seamless-scroll'
// import 'swiper/swiper.min.css'
// import swiper from 'vue-awesome-swiper'
import popup from './components/popupTip.vue';
import VueScroller from 'vue-scroller'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueScroller)
Vue.component('popup',popup )
// Vue.use(swiper)
Vue.use(vueSeamlessScroll)
Vue.use(ElementUI);
Vue.component('Pagination', Pagination)
Vue.config.productionTip = false
Vue.prototype.$utils = utils;
Vue.use(VueClipboard)  //必须这样子引用 否则会报错的

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
