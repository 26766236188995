<template>
  <div class="register">
    <div class="title"></div>
    <div class="centont">
      <div class="list">
        <div class="page-bnt page-left" @click="handlePerData"  v-if="list.length  >7">
          <i class="el-icon-arrow-left"></i> 
        </div>
        <div class="list-all" >
          <div class="list-bg"></div>
          <div class="list-item"  ref="listImg">
            <div class="item-all" v-for="(item,idx) in list" :key="idx" :style="{transform:`translate3d(-${widthShow}px, 0px, 0px)`,transition: 'all 0.5s' }" >
              <div class="item-name" :class="{'item-name-show':item.isReceive == 1}">第{{idx+1}}天</div>
              <div class="item-img" :class="{'item-img-show':item.isReceive == 2}">
                
                <img class="item-img-icon item-img-icon1" v-if="item.rewardType == 1" src="~@/assets/images/balance/first/1.png"/>
                <img class="item-img-icon item-img-icon2" src="~@/assets/images/balance/first/2.png" v-else-if="item.rewardType == 2"/>
                <img class="item-img-icon item-img-icon3" v-else-if="item.rewardType == 3" src="~@/assets/images/balance/first/3.png"/>
                <img class="item-img-icon item-img-icon0" v-else src="~@/assets/images/balance/first/4.png"/>
              </div>
              <div class="item-shopname" :class="{'item-shopname-show':item.isReceive == 1}">
                {{item.name || '签到成功'}}
              </div>
              <div class="item-bnt item-bnt-show" v-if="item.canSignIn == 2" @click="handleSignInData(item,idx)">签到</div>
              <div v-else>
                <div v-if='item.lastTime' >
                  <div v-if="item.isReceive == 1"  class="item-bnt">
                    <count-down  class="right-time"
                      :required='false'
                      :minutesTxt="':'" :secondsTxt="''"
                      v-on:start_callback="countDownS_cb()" 
                      v-on:end_callback="countDownE_cb(item,idx)" 
                      :startTime="startTime" 
                      :endTime="item.lastTime" >
                      </count-down>

                  </div>
                  <!-- <div v-else>已签到</div> -->

                </div>
                <div v-if="item.isReceive == 2"  class="item-bnt">已签到</div>
                
              </div>
            </div>
          </div>
        </div>
        <div class="page-bnt page-right" @click="handleNextData" v-if="list.length >7">
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="rule">
        <img class="rule-title" src="~@/assets/images/balance/first/rule-title.png"/>
        <img class="rule-text" src="https://image.lingane-sport.com/client/sginIn/qdgz.png"/>
      </div>

    </div>
  </div>
</template>
<script>
import CountDown from 'vue2-countdown'
import { encryptByDESModeCBCUtf8to64, decryptByDESModeCBC,getAstro }  from '@/common/utils/des'
import { signInList,signIn, } from "@/api/balance/first";
export default {
  name: "register",
  data() {
    return {
      list: [],
      longWidth: '100%',
      widthShow:0,
      startTime: null
    };
  }, 
  components: {CountDown},
  computed: {
  },
  methods: {
    handleSignInData(item,idx) {
    let info = JSON.parse(decryptByDESModeCBC(sessionStorage.secret));
    this.shopVal  = info.shopId
      let params = {
        lgUserId: sessionStorage.lgUserId,
        signInId: item.signInId,
        shopId: info.shopId,
        shopName: sessionStorage.shopName,
      }
      signIn(params).then(res => {
        if (res.code == 200) {
          this.list[idx].canSignIn = 1
          this.list[idx].isReceive = 2
          this.$message({
              message: res.msg || '签到成功!',
              type: 'success',
              customClass: 2000
          });
          this.$forceUpdate()

        }else{
          this.$message.error(res.msg || '签到失败!');
        }
      });
    },
    countDownS_cb() {

    },
    countDownE_cb(item,idx) {
      this.list[idx].canSignIn = 2
      
    },
    // 上一页
    handlePerData() {
      if(this.list.length > 7) {
        let imgDow =  this.$refs.listImg
        let length = imgDow.offsetWidth
        let width = Math.ceil(length/7) 
        if(this.widthShow < 1) {
          this.widthShow = 0
        } else{
          if(this.list.length%7 == 0 ) {
            this.widthShow =this.widthShow -  (width* 7) 

          }else{
            this.widthShow =this.widthShow -  (width* 2) 
          }
        }
      }
      this.$forceUpdate()

    },
    // 下一页
    handleNextData() {
      let imgDow =  this.$refs.listImg
      let length = imgDow.offsetWidth
    
      if(this.list.length > 13) {
        let width = Math.ceil(length/7) 
        let len = width * (this.list.length/2) -(70*2)
        if(len < this.widthShow) {
          this.widthShow = this.widthShow
        } else{
          if(this.list.length%7 == 0 ) {
            this.widthShow =this.widthShow +  (width* 7) 

          }else{
            this.widthShow =this.widthShow +  width*2
          }
        }
        this.$forceUpdate()
      }else if(this.list.length  < 8 ) {
        this.widthShow = 0
      }else if(this.list.length  < 10 ) {
        let width = Math.ceil(length/7) 
        let len = width * (this.list.length/4) -(70*2)
        if(len < this.widthShow) {
          this.widthShow = this.widthShow
        } else{
          this.widthShow =this.widthShow +  width
        }
      }else if(this.list.length  < 13 ) {
        let width = Math.ceil(length/7) 
        let len = width * (this.list.length/2) -(70*2)
        if(len < this.widthShow) {
          this.widthShow = this.widthShow
        } else{
          this.widthShow =this.widthShow +  width
        }

      }else if(this.list.length  < 14 ) {
        let width = Math.ceil(length/7) 
        let len = width * (this.list.length/2) -(70)
        if(len < this.widthShow) {
          this.widthShow = this.widthShow
        } else{
          this.widthShow =this.widthShow +  width
        }

      }else{
        let width = Math.ceil(length/7) 
        let len = width * (this.list.length/3) -(70*2)
        if(len < this.widthShow) {
          this.widthShow = this.widthShow
        } else{
          if(this.list.length/7 == 1 ) {
            this.widthShow =this.widthShow +  (width* 7) 
          }else{
            this.widthShow =this.widthShow +  width
          }
        }
      }
    },
    handleListData() {
      this.widthShow = 0
      signInList({lgUserId: sessionStorage.lgUserId }).then(res => {
        if (res.code == 200) {
          this.startTime = new Date().getTime()
          let  list = res.data.list
          this.list = list
          if(res.data.today) {
            if(res.data.today < 8) {
              let imgDow =  this.$refs.listImg
              let length = imgDow.offsetWidth
              let width = Math.ceil(length/7) 
              let len = width * (this.list.length/3) -(70*2)
              if(res.data.today < 2) {
                this.widthShow = 0
              }else{
                if(this.list.length > 8 ) {
                  let num = Math.ceil(res.data.today/2) 
                  this.widthShow =this.widthShow + (width* num) 
                }

              }

            }else if(res.data.today > 7){
              let imgDow =  this.$refs.listImg
              let length = imgDow.offsetWidth
              let width = Math.ceil(length/7) 
              let len = width * (this.list.length/3) -(70*2)
              if(this.list.length > 8 ) {
                let num = Math.ceil(res.data.today/2) 
                if((width* num) > length) {
                  this.widthShow =length + width
                }else{
                  this.widthShow =width* num
                }
              }
            }
          }
          this.$forceUpdate()
        }
      });
    }

  },
  created() {},
  mounted() {
    // this.handleListData()

  },
  destroyed() {}
};
</script>
<style lang="less" scoped>

@import "./register.less";
</style>
